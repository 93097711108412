import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Article from '../../../models/Article';

@Component({
	selector: 'app-checkout-recommendation-article',
	templateUrl: './checkout-recommendation-article.component.html',
	styleUrls: ['./checkout-recommendation-article.component.scss'],
})
export class CheckoutRecommendationArticleComponent implements OnInit {
	@Input() article: Article;
	@Output() add = new EventEmitter<Article>();
	@Input() price: string;

	constructor() {}

	ngOnInit() {}
}
