import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { environment } from '../../../environments/environment';
import Order from '../../../models/Order';
import ArticleGroup from '../../../models/ArticleGroup';
import { orderTotalPrice, totalPrice } from '../../../utils/order-utils';
import { PaymentMethod } from '../../../enums/PaymentMethod';
import Payment from '../../../models/Payment';
import Article from '../../../models/Article';
import { OrderType } from '../../../enums/OrderType';
import { PreorderType } from '../../../enums/PreorderType';
import Utils from '../../../utils';

declare var gtag: any;

@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	static orderHasProduct = false;
	static enabled = false;

	constructor(private analytics: AngularFireAnalytics) {}

	async setAnalyticsCollectionEnabled(enabled: boolean) {
		AnalyticsService.enabled = enabled;
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
		const atPixels = window.location.host === 'clickandeat.deinsubway.at';
		this.loadGooglePixel(enabled ? 'AW-768689571' : null);
		this.loadFacebookPixel(enabled ? (atPixels ? '613424440366813' : '611240812966114') : null);
		this.loadTikTokPixel(enabled ? 'CBHSL3BC77U9VOS1I0J0' : null);
		this.loadTwitterPixel(enabled ? 'o9l5e' : null);
		this.loadAdformPixel(enabled ? (atPixels ? '503078' : null) : null);
		this.loadTeadsPixel(enabled ? (atPixels ? '6814' : null) : null);
	}

	logVersion() {
		this.logEvent('version', {
			customer: environment.customerGroup,
			version: environment.version,
		});
	}

	addToCart(order: Order, articleGroup: ArticleGroup) {
		this.logEvent('add_to_cart', {
			currency: order.currency,
			value: orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
		});
	}

	beginCheckout(order: Order) {
		this.logEvent('begin_checkout', {
			currency: order.currency,
			value: orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		});
	}

	emailVerificationPageOpened() {
		this.logEvent('email_verification_page_opened');
	}

	orderVerified(order: Order) {
		this.logEvent('order_verified', {
			currency: order.currency,
			value: orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		});
		this.checkoutProgress(order, null, 2);

		this.gtagTrack('event', 'conversion', {
			send_to: 'AW-768689571/kpkQCICL29wDEKOLxe4C',
			value: orderTotalPrice(order, true, true),
			currency: 'EUR',
		});
	}

	checkoutProgress(order: Order, paymentMethod: PaymentMethod, checkoutStep: number) {
		this.logEvent('checkout_progress', {
			currency: order.currency,
			value: orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			checkout_option: paymentMethod,
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		});
	}

	paymentLoadingFailed(order: Order, paymentMethod: PaymentMethod) {
		this.logEvent('payment_loading_failed', {
			currency: order.currency,
			value: orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			checkout_option: paymentMethod,
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		});
	}

	paymentFailed(order: Order, payment: Payment, error: any) {
		this.logEvent('payment_failed', {
			order: order._id,
			payment: payment._id,
			error,
		});
	}

	paymentLoaded(order: Order, paymentMethod: PaymentMethod) {
		this.logEvent('payment_loaded', {
			currency: order.currency,
			value: orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			checkout_option: paymentMethod,
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		});
		this.checkoutProgress(order, paymentMethod, 3);
	}

	purchase(order: Order, payment: Payment) {
		this.logEvent('purchase', {
			currency: order.currency,
			value: orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			transaction_id: payment._id,
			shipping: order.preorder.deliveryFee ? order.preorder.deliveryFee : undefined,
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		});
		this.gtagTrack('event', 'conversion', {
			send_to: 'AW-768689571/sskvCPPCpNwDEKOLxe4C',
			value: orderTotalPrice(order, true, true),
			currency: 'EUR',
			transaction_id: payment._id,
		});
	}

	search(searchTerm: string) {
		this.logEvent('search', {
			search_term: searchTerm,
		});
	}

	selectContentProduct(article: Article, orderType: OrderType, preorderType: PreorderType) {
		this.logEvent('select_content', {
			content_type: 'product',
			items: {
				id: article.masterId,
				name: article.name.de,
				quantity: 1,
				price: Utils.getPrice(article, orderType, preorderType, null),
			},
		});
	}

	visitMenuPage() {
		this.logEvent('visit_menu_page');
		this.gtagTrack('event', 'conversion', {
			send_to: 'AW-768689571/ND1dCLn82twDEKOLxe4C',
			value: 1.0,
			currency: 'EUR',
		});
	}

	openOrderContent() {
		this.logEvent('visit_order_content');
	}

	orderHasProducts() {
		if (AnalyticsService.orderHasProduct) {
			return;
		}
		AnalyticsService.orderHasProduct = true;
		this.logEvent('order_has_products');
	}

	customerBeginsCheckout() {
		this.logEvent('checkout_modal_opened');
	}

	paymentModalOpened() {
		this.logEvent('payment_modal_opened');
	}

	paymentSuccess() {
		this.logEvent('payment_success');
	}

	beginArticleEdit(article: Article) {
		const eventParams = {
			article: article.name.de,
			id: article._id,
		};
		this.logEvent('begin_article_edit', eventParams);
	}

	stepArticleEdit(article: Article, step: number) {
		const eventParams = {
			article: article.name.de,
			step,
			id: article._id,
		};
		this.logEvent('step_article_edit', eventParams);
	}

	finishArticleEdit(article: Article, step: number) {
		const eventParams = {
			article: article.name.de,
			step,
			id: article._id,
		};
		this.logEvent('finish_article_edit', eventParams);
	}

	cancelArticleEdit(article: Article, step: number) {
		this.logEvent('cancel_article_edit', {
			article: article.name.de,
			step,
			id: article._id,
		});
	}

	loadFacebookPixel(id: string) {
		const nodeId = 'facebook-pixel';
		const noscriptId = 'facebook-pixel-noscript';
		if (id) {
			const script = document.getElementById(nodeId) || document.createElement('script');
			script.id = nodeId;
			script.innerHTML =
				'!function(f,b,e,v,n,t,s)\n' +
				'{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
				'n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
				"if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
				'n.queue=[];t=b.createElement(e);t.async=!0;\n' +
				't.src=v;s=b.getElementsByTagName(e)[0];\n' +
				"s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
				"'https://connect.facebook.net/en_US/fbevents.js');\n" +
				"fbq('init', '" +
				id +
				"');\n" +
				"fbq('track', 'PageView');";
			if (!script.parentNode) {
				document.body.appendChild(script);
			}
			const noscript = document.getElementById(noscriptId) || document.createElement('noscript');
			noscript.id = noscriptId;
			noscript.innerHTML =
				'<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=' + id + '&ev=PageView&noscript=1" />';
			if (!noscript.parentNode) {
				document.body.appendChild(noscript);
			}
		} else {
			const script = document.getElementById(nodeId);
			const noscript = document.getElementById(noscriptId);
			if (script) {
				document.removeChild(script);
			}
			if (noscript) {
				document.removeChild(noscript);
			}
		}
	}

	loadTikTokPixel(id: string) {
		const nodeId = 'tiktok-pixel';
		if (id) {
			const script = document.getElementById(nodeId) || document.createElement('script');
			script.id = nodeId;
			script.innerHTML =
				'!function (w, d, t) {\n' +
				'\t\t  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++\n' +
				')ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};\n' +
				'\t\t\n' +
				"\t\t  ttq.load('" +
				id +
				"');\n" +
				'\t\t  ttq.page();\n' +
				"\t\t}(window, document, 'ttq');";
			if (!script.parentNode) {
				document.body.appendChild(script);
			}
		} else {
			const script = document.getElementById(nodeId);
			if (script) {
				document.removeChild(script);
			}
		}
	}

	loadTwitterPixel(id: string) {
		const nodeId = 'twitter-pixel';
		if (id) {
			const script = document.getElementById(nodeId) || document.createElement('script');
			script.id = nodeId;
			script.innerHTML =
				'!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);\n' +
				"},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',\n" +
				"a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');\n" +
				'// Insert Twitter Pixel ID and Standard Event data below\n' +
				"twq('init','" +
				id +
				"');\n" +
				"twq('track','PageView');";
			if (!script.parentNode) {
				document.body.appendChild(script);
			}
		} else {
			const script = document.getElementById(nodeId);
			if (script) {
				document.removeChild(script);
			}
		}
	}

	loadGooglePixel(id: string) {
		const nodeId = 'google-pixel';
		if (id) {
			const script = (document.getElementById(nodeId) || document.createElement('script')) as HTMLScriptElement;
			script.id = nodeId;
			script.async = true;
			script.src = 'https://www.googletagmanager.com/gtag/js?id=' + id;
			script.innerHTML =
				'window.dataLayer = window.dataLayer || [];\n' +
				'function gtag(){dataLayer.push(arguments);}\n' +
				"gtag('js', new Date());\n" +
				"gtag('config', '" +
				id +
				"');";
			if (!script.parentNode) {
				document.head.appendChild(script);
			}
		} else {
			const script = document.getElementById(nodeId);
			if (script) {
				document.removeChild(script);
			}
		}
	}

	loadAdformPixel(id: string) {
		const nodeId = 'adform-pixel';
		const noscriptId = 'adform-pixel-noscript';
		if (id) {
			const script = document.getElementById(nodeId) || document.createElement('script');
			script.id = nodeId;
			script.innerHTML =
				'window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);\n' +
				'    window._adftrack.push({\n' +
				"        HttpHost: 'track.adform.net',\n" +
				'        pm: ' +
				id +
				'\n' +
				'    });\n' +
				"    (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();";
			if (!script.parentNode) {
				document.body.appendChild(script);
			}
			const noscript = document.getElementById(noscriptId) || document.createElement('noscript');
			noscript.id = noscriptId;
			noscript.innerHTML =
				'<p style="margin:0;padding:0;border:0;"><img src="https://track.adform.net/Serving/TrackPoint/?pm=' +
				id +
				'" width="1" height="1" alt="" /></p>';
			if (!noscript.parentNode) {
				document.body.appendChild(noscript);
			}
		} else {
			const script = document.getElementById(nodeId);
			const noscript = document.getElementById(noscriptId);
			if (script) {
				document.removeChild(script);
			}
			if (noscript) {
				document.removeChild(noscript);
			}
		}
	}

	loadTeadsPixel(id: string) {
		const nodeId = 'teads-pixel';
		if (id) {
			const script = (document.getElementById(nodeId) || document.createElement('script')) as HTMLScriptElement;
			script.id = nodeId;
			script.src = 'https://p.teads.tv/teads-fellow.js';
			script.async = true;
			script.innerHTML = 'window.teads_e = window.teads_e || [];\n' + 'window.teads_buyer_pixel_id = ' + id + ';\n';
			if (!script.parentNode) {
				document.body.appendChild(script);
			}
		} else {
			const script = document.getElementById(nodeId);
			if (script) {
				document.removeChild(script);
			}
		}
	}

	gtagTrack(event: any, param1: any, param2: any) {
		if (AnalyticsService.enabled) {
			gtag(event, param1, param2);
			console.log(param2);
		}
	}

	private logEvent(eventName: string, eventParams: any = {}) {
		this.analytics.logEvent(eventName, eventParams).then(() => {
			if (environment.production) {
				return;
			}
			console.log(eventName, eventParams);
		});
	}
}
