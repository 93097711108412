import { ChangeDetectorRef, Component } from '@angular/core';
import { Api } from '../../../api/api';
import { RepositoryService } from '../../services/repository/repository.service';
import { Router } from '@angular/router';
import 'firebase/compat/auth';
import * as moment from 'moment';
import Order from '../../../models/Order';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page.component';
import RepositoryDirective from '../repository-directive';
import Utils from '../../../utils';

@Component({
	selector: 'app-my-orders',
	templateUrl: './my-orders.page.html',
	styleUrls: ['./my-orders.page.scss'],
})
export class MyOrdersPage extends RepositoryDirective {
	static url = 'my-orders';

	orders: Order[] = [];
	loading = false;

	constructor(
		public repository: RepositoryService,
		private cdr: ChangeDetectorRef,
		private router: Router
	) {
		super(repository);
	}

	get openOrders(): Order[] {
		return this.orders.filter(value => moment(value.orderAt).add(30, 'minutes').isSameOrAfter(moment()));
	}

	get doneOrders(): Order[] {
		return this.orders.filter(value => moment(value.orderAt).add(30, 'minutes').isBefore(moment()));
	}

	static navigate(router: Router) {
		router.navigateByUrl(MyOrdersPage.url);
	}

	onUser() {
		super.onUser();
		this.loadOrders();
	}

	ionViewDidEnter() {
		this.orders = [];
		this.loadOrders();
	}

	loadOrders() {
		this.orders = [];
		if (this.user === undefined || this.user === null) {
			this.cdr.detectChanges();
			return;
		}
		Api.getCustomerOrders(this.user.uid)
			.then(res => {
				const orders = res.data.withPayment;
				if (orders.length > 0) {
					this.orders = orders
						// only normal orders no refunded
						.filter(order => Utils.isEmpty(order.referenceOrder))
						.sort((o1, o2) => {
							const orderAtResult = Utils.dateCompare(o1.orderAt, o2.orderAt);
							if (orderAtResult === 0) {
								return Utils.dateCompare(o1.createdAt, o2.createdAt);
							}
							return orderAtResult;
						});
				} else {
					this.orders = [];
				}
				this.loading = false;
				this.cdr.detectChanges();
			})
			.catch(err => {
				console.log(err);
				this.loading = false;
				this.cdr.detectChanges();
			});
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
