import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Api } from '../../../api/api';
import Utils from '../../../utils';
import Order from '../../../models/Order';
import { RepositoryService } from '../../services/repository/repository.service';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import { Subscription } from 'rxjs';
import { applyPromo, orderTotalPrice, removePromo } from '../../../utils/order-utils';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
	selector: 'app-promo-code',
	templateUrl: './promo-code.component.html',
	styleUrls: ['./promo-code.component.scss'],
})
export class PromoCodeComponent implements OnInit, OnDestroy {
	@Output()
	error = new EventEmitter<string>();
	@Output()
	success = new EventEmitter<Order>();
	@Output()
	deletePromo = new EventEmitter<Order>();
	input: string;
	showInput = false;
	loading = false;
	inactive = true;
	private _error: string;
	private deletePromoSubscription: Subscription;

	constructor(
		private repository: RepositoryService,
		private translate: TranslateService,
		private analytics: AnalyticsService
	) {}

	private _order: Order;

	get order(): Order {
		return this._order;
	}

	@Input()
	set order(order: Order) {
		this._order = order;
		if (this._order && this._order.promoCode && this._order.promoCode.code) {
			this.input = this._order.promoCode.code;
		}
	}

	private _user: firebase.User;

	get user(): firebase.User {
		return this._user;
	}

	@Input()
	set user(value: firebase.User) {
		this._user = value;
		this.inactive = !this.user || this.user.isAnonymous;
		if (this.inactive && this.order && this.order.promoCode) {
			this.removePromoAndEmit();
		}
	}

	get promoError(): string {
		return this._error;
	}

	set promoError(value: string) {
		this._error = value;
		if (this._error) {
			this.loading = false;
			this.error.emit(value);
		}
	}

	ngOnInit() {
		this.deletePromoSubscription = this.deletePromo.subscribe(() => (this.promoError = null));
	}

	ngOnDestroy() {
		this.deletePromoSubscription.unsubscribe();
		this.deletePromoSubscription = null;
	}

	async checkPromoCode() {
		if (!this.input || this.input.replace(' ', '').length === 0) {
			this.promoError = this.translate.instant('promo_code.not_valid');
			return;
		}
		this.loading = true;
		this.promoError = null;
		try {
			const promoCode = (
				await Api.getPromoCode({
					code: this.input,
					venue: this.order.venue,
				})
			).data;
			promoCode.mov = +promoCode.mov;
			if (orderTotalPrice(this.order, false, false) < promoCode.mov) {
				this.promoError = this.translate.instant('promo_code.mov_not_reached', {
					mov: Utils.numberToCurrency(promoCode.mov, this.order.currency),
				});
				return;
			}
			if (promoCode.availability) {
				if (!Utils.resolveByType(promoCode.availability, this.order.type, this.order.preorder.type, null, true)) {
					this.promoError = this.translate.instant('promo_code.wrong_order_type');
					return;
				}
			}
			try {
				try {
					const orderCopy = JSON.parse(JSON.stringify(this.order));
					orderCopy.promoCode = promoCode;
					orderCopy.userUid = this.repository._user.uid;
					orderCopy.orderAt = null;
					await Api.applyPromoCode(orderCopy);
				} catch (e) {
					this.promoError = Utils.axiosErrorToMessage(this.translate, e);
					return;
				}
				this.success.emit(applyPromo(this.translate, this.repository._venue, this.order, promoCode, this.analytics));
				this.loading = false;
				this.showInput = false;
			} catch (e) {
				this.promoError = e;
			}
		} catch (e) {
			if (e.response.status === 404) {
				this.promoError = this.translate.instant('promo_code.not_valid');
				return;
			}
			this.promoError = Utils.axiosErrorToMessage(this.translate, e);
			return;
		}
	}

	resetError() {
		if (this._error) {
			this.promoError = null;
		}
	}

	editPromo() {
		const promoCode = this.order.promoCode;
		this.order = removePromo(this.order);
		if (promoCode && promoCode.code) {
			this.order.promoCode = promoCode.code;
		}
		this.showInput = true;
	}

	removePromoAndEmit() {
		if (this.order && this.user) {
			this.deletePromo.emit(removePromo(this.order));
		}
	}
}
