import { Component } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import { SignInPage } from '../../pages/sign-in/sign-in.page';
import { Router } from '@angular/router';
import { SignUpPage } from '../../pages/sign-up/sign-up.page';
import { PopoverController } from '@ionic/angular';
import { HomePage } from '../../pages/home/home.page';
import 'firebase/compat/auth';
import { MyOrdersPage } from '../../pages/my-orders/my-orders.page';
import { AccountPage } from '../../pages/account/account.page';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { PrivacyPage } from '../../pages/privacy/privacy.page';
import { TosPage } from '../../pages/tos/tos.page';
import { ImpressumPage } from '../../pages/impressum/impressum.page';
import { FaqPage } from '../../pages/faq/faq.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import RepositoryDirective from '../../pages/repository-directive';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { FormSendPage } from 'src/app/pages/form-send/form-send.page';

@Component({
	selector: 'app-menu-popover',
	templateUrl: './menu-popover.component.html',
	styleUrls: ['./menu-popover.component.scss'],
})
export class MenuPopoverComponent extends RepositoryDirective {
	version = environment.version;

	constructor(
		public repository: RepositoryService,
		private router: Router,
		private popoverCtrl: PopoverController,
		private auth: AngularFireAuth,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService
	) {
		super(repository);
	}

	async signIn() {
		await this.popoverCtrl.dismiss();
		await SignInPage.navigate(this.router);
	}

	async signUp() {
		await this.popoverCtrl.dismiss();
		SignUpPage.navigate(this.router);
	}

	async mySpace() {
		await this.popoverCtrl.dismiss();
		await AccountPage.navigate(this.router);
	}

	async faq() {
		await this.popoverCtrl.dismiss();
		FaqPage.navigate(this.router);
	}

	async myOrders() {
		await this.popoverCtrl.dismiss();
		MyOrdersPage.navigate(this.router);
	}

	async logout() {
		try {
			await this.auth.signOut();
			this.snackbarCtrl.open(this.translate.instant('menu_popover.logout_success'), null, {
				duration: 2000,
			});
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('menu_popover.logout_error'), null, {
				duration: 2000,
			});
		}
		await this.popoverCtrl.dismiss();
	}

	async home() {
		await this.popoverCtrl.dismiss();
		await HomePage.navigate(this.router);
	}

	async privacy() {
		await this.popoverCtrl.dismiss();
		await PrivacyPage.navigate(this.router);
	}

	async tos() {
		await this.popoverCtrl.dismiss();
		await TosPage.navigate(this.router);
	}

	async impressum() {
		let venueId = '';
		if (this.venue) {
			venueId = this.venue._id;
		}
		await this.popoverCtrl.dismiss();
		if (venueId) {
			await ImpressumPage.navigateWithParams(this.router, { venueId });
		} else {
			await ImpressumPage.navigate(this.router);
		}
	}
	async openForm() {
		await this.popoverCtrl.dismiss();
		await FormSendPage.navigate(this.router);
	}
}
