export enum PaymentMethod {
	PAYPAL = 'paypal',
	CASH = 'cash',
	CREDIT_CARD = 'credit_card',
	SEPA = 'sepa',
	BALANCE = 'balance',
	POS = 'pos',
	SOFORTBANKING = 'sofortbanking',
	GPAY = 'google_pay',
	APAY = 'apple_pay',
	WORLDLINE = 'world_line',
}
