import { Component, Input, OnInit } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import { PopoverController } from '@ionic/angular';
import { HomePopoverComponent } from '../home-popover/home-popover.component';
import { PreorderType } from '../../../enums/PreorderType';
import { HomePage } from '../../pages/home/home.page';
import { MenuPage } from '../../pages/menu/menu.page.component';
import { Router } from '@angular/router';
import RepositoryDirective from '../../pages/repository-directive';
import Venue from '../../../models/Venue';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent extends RepositoryDirective implements OnInit {
	@Input() preorderType: PreorderType;
	@Input() showMenu = true;
	@Input() ven: Venue;
	pt = PreorderType;

	constructor(
		repository: RepositoryService,
		private popoverCtrl: PopoverController,
		private router: Router
	) {
		super(repository);
	}

	get type(): PreorderType {
		if (!this.order || !this.order.preorder || !this.order.preorder.type) {
			return this.preorderType;
		}
		return this.order.preorder.type;
	}

	getVenue() {
		return this.ven ? this.ven : this.venue;
	}

	async presentHomePopover(ev: any) {
		const popover = await this.popoverCtrl.create({
			component: HomePopoverComponent,
			event: ev,
			translucent: true,
			mode: 'ios',
			cssClass: 'home-popover',
		});
		return await popover.present();
	}

	ngOnInit() {
		super.ngOnInit();
	}

	home() {
		if (this.order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
