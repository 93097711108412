import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { ActionSheetController, ModalController, PickerController, PopoverController } from '@ionic/angular';

@Injectable()
export class ModalNavigationGuard implements CanDeactivate<Component> {
	constructor(
		private pickerCtrl: PickerController,
		private modalCtrl: ModalController,
		private popoverCtrl: PopoverController,
		private actionSheetCtrl: ActionSheetController
	) {}

	async canDeactivate(component: Component, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		try {
			await this.pickerCtrl.dismiss();
			history.pushState(null, null, location.href);
			console.log('picker closed');
			return false;
		} catch (e) {}
		try {
			await this.modalCtrl.dismiss();
			history.pushState(null, null, location.href);
			console.log('modal closed');
			return false;
		} catch (e) {}
		try {
			await this.popoverCtrl.dismiss();
			history.pushState(null, null, location.href);
			console.log('popover closed');
			return false;
		} catch (e) {}
		try {
			await this.actionSheetCtrl.dismiss();
			history.pushState(null, null, location.href);
			console.log('actionSheet closed');
			return false;
		} catch (e) {}
		return true;
	}
}
