import { Component, EventEmitter, Input, Output } from '@angular/core';
import Venue from '../../../models/Venue';
import { WorkingHoursModalComponent } from '../working-hours-modal/working-hours-modal.component';
import { ModalController } from '@ionic/angular';
import Utils from '../../../utils';
import { PreorderType } from '../../../enums/PreorderType';
import moment, { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { TimeUtils } from '../../../utils/time-utils';
import { Api } from 'src/api/api';
import { Legal } from 'src/models/Legal';
import { ShortInfoModalComponent } from '../short-info-modal/short-info-modal.component';

@Component({
	selector: 'app-venue-suggestion',
	templateUrl: './venue-suggestion.component.html',
	styleUrls: ['./venue-suggestion.component.scss'],
})
export class VenueSuggestionComponent {
	@Input() type: PreorderType;
	@Output() venuePick = new EventEmitter<{ venue: Venue; type: PreorderType }>();
	utils = Utils;
	isVenueOpen = false;
	pt = PreorderType;
	@Input()
	overridePostalDelivery = false;
	firstDeliveryTime: string = null;
	firstTakeAwayTime: string = null;
	@Input()
	isDeliveryAlternative = false;
	@Input()
	selected = false;
	preorderTypes = [PreorderType.TAKE_AWAY, PreorderType.PARK_COLLECT, PreorderType.DELIVERY];
	accepts = {};
	legal: Legal;

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}

	private _venue: Venue;

	get venue(): Venue {
		return this._venue;
	}

	@Input()
	set venue(venue: Venue) {
		this._venue = venue;
		this.firstDeliveryTime = null;
		this.firstTakeAwayTime = null;
		this.accepts = {};
		this.accepts[PreorderType.DELIVERY] = false;
		this.accepts[PreorderType.TAKE_AWAY] = false;
		this.accepts[PreorderType.PARK_COLLECT] = false;
		this.isVenueOpen = false;
		if (venue === undefined || venue === null) {
			return;
		}
		this.venueLegal(venue);
		this.isVenueOpen = Utils.isVenueOpen(venue);
		console.log('venueOpen', this.isVenueOpen);
		const firstTakeAwaySchedule = TimeUtils.hoursToSchedule(
			Utils.getRelevantSchedule(venue, PreorderType.TAKE_AWAY)[0],
			true,
			venue?.specialOpeningHours ?? []
		);
		const firstDeliverySchedule = TimeUtils.hoursToSchedule(
			Utils.getRelevantSchedule(venue, PreorderType.DELIVERY)[0],
			true,
			venue.specialOpeningHours ?? []
		);
		const now = moment().add(30, 'minutes');

		const checkTakeAwaySlots = !this.isVenueOpen || !firstTakeAwaySchedule || firstTakeAwaySchedule.closedAt.isBefore(now);
		const checkDeliverySlots = !this.isVenueOpen || !firstDeliverySchedule || firstDeliverySchedule.closedAt.isBefore(now);

		if (checkTakeAwaySlots) {
			Utils.getSlots(venue, PreorderType.TAKE_AWAY).then(slots => {
				this.accepts[PreorderType.TAKE_AWAY] = slots.length > 0 && Utils.venueAcceptsOrders(venue, PreorderType.TAKE_AWAY);
				this.accepts[PreorderType.PARK_COLLECT] =
					this.accepts[PreorderType.TAKE_AWAY] && venue.preorderParkCollect && Utils.isVenueOpen(venue);
				if (this.accepts[PreorderType.TAKE_AWAY]) {
					this.firstTakeAwayTime = this.timeToText(venue, moment(slots[0].time));
				}
			});
		} else {
			console.log('not checking takeaway slots venue is open or not closing in 30 mins ' + venue.name);
			this.accepts[PreorderType.TAKE_AWAY] = Utils.venueAcceptsOrders(venue, PreorderType.TAKE_AWAY);
			this.accepts[PreorderType.PARK_COLLECT] =
				this.accepts[PreorderType.TAKE_AWAY] && venue.preorderParkCollect && Utils.isVenueOpen(venue);
		}
		if (checkDeliverySlots) {
			Utils.getSlots(venue, PreorderType.DELIVERY).then(slots => {
				this.accepts[PreorderType.DELIVERY] =
					slots.length > 0 && Utils.venueAcceptsOrders(venue, PreorderType.DELIVERY, this.overridePostalDelivery);
				if (this.accepts[PreorderType.DELIVERY]) {
					this.firstDeliveryTime = this.timeToText(venue, moment(slots[0].time));
				}
			});
		} else {
			console.log('not checking delivery slots venue is open or not closing in 30 mins ' + venue.name);
			this.accepts[PreorderType.DELIVERY] = Utils.venueAcceptsOrders(venue, PreorderType.DELIVERY, this.overridePostalDelivery);
		}
	}

	async venueLegal(venue: Venue) {
		try {
			this.legal = (await Api.getLegal(venue._id)).data;
		} catch (e) {
			console.error('Error while getting legal information');
			console.error(e);
		}
	}

	async openHtml(html: string) {
		await ShortInfoModalComponent.show(this.modalCtrl, html);
	}

	selectVenue(venue: Venue, type: PreorderType) {
		if (!this.accepts[type]) {
			return;
		}
		this.venuePick.emit({ venue, type });
	}

	openModal(venue: Venue) {
		WorkingHoursModalComponent.show(this.modalCtrl, venue, this.type);
	}

	timeToText(venue: Venue, time: Moment | null) {
		if (time && venue.slot) {
			const now = moment().add(
				Math.abs((moment().minutes() % venue.slot.interval) - venue.slot.interval) % venue.slot.interval,
				'minutes'
			);
			const roundedTime = time.add(
				Math.abs((time.minutes() % venue.slot.interval) - venue.slot.interval) % venue.slot.interval,
				'minutes'
			);
			const relevantTime = now.isAfter(roundedTime) ? now : roundedTime;
			const isToday = now.dayOfYear() === relevantTime.dayOfYear() && now.year() === relevantTime.year();
			return (isToday ? '' : this.translate.instant('venue_suggestion.tomorrow') + ' ') + relevantTime.format('HH:mm');
		} else {
			return null;
		}
	}
}
