import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RepositoryService } from '../../services/repository/repository.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import validator from 'validator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountPage } from '../account/account.page';
import { EmailConfirmationPage } from '../email-confirmation/email-confirmation.page';
import { Api } from '../../../api/api';
import { TosPage } from '../tos/tos.page';
import { Gender } from 'src/enums/Gender';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page.component';
import { TranslateService } from '@ngx-translate/core';
import { ValidationUtils } from '../../../utils/validation-utils';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import EmailAuthProvider = firebase.auth.EmailAuthProvider;

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.page.html',
	styleUrls: ['./sign-up.page.scss'],
})
export class SignUpPage {
	static url = 'sign-up';

	showRePassword = false;
	showPassword = false;

	email = '';
	password = '';
	rePassword = '';
	fullName = '';
	agbChecked = false;
	loading = false;
	isValid = false;
	Gender = Gender;
	gender: Gender = null;

	user: firebase.User;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private auth: AngularFireAuth,
		private snackbarCtrl: MatSnackBar,
		private analytics: AnalyticsService
	) {
		this.user = repository._user;
		const userSubscription = repository.user.subscribe(user => {
			this.user = user;
			if (user && !user.isAnonymous) {
				AccountPage.navigate(router);
				userSubscription.unsubscribe();
			}
		});
	}

	static navigate(router: Router) {
		router.navigateByUrl(SignUpPage.url);
	}

	validate() {
		if (this.gender === null) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.gender'), null, {
				duration: 2000,
			});
			return;
		}
		if (this.fullName.split(' ').length < 2) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.name'), null, {
				duration: 2000,
			});
			return;
		}
		if (!validator.isEmail(this.email)) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.email'), null, {
				duration: 2000,
			});
			return;
		}
		if (!ValidationUtils.validatePassword(this.password)) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.password'), null, {
				duration: 6000,
			});
			return;
		}
		if (this.password !== this.rePassword) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.re_password'), null, {
				duration: 2000,
			});
			return;
		}
		if (this.user === null) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.user'), null, {
				duration: 2000,
			});
			return;
		}
		if (!this.user.isAnonymous) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.already_signed_in'), null, {
				duration: 2000,
			});
			return;
		}
		if (!this.agbChecked) {
			this.isValid = false;
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.agb'), null, {
				duration: 2000,
			});
			return;
		}
		this.isValid = true;

		console.log('isvalid', this.isValid);
	}

	signUp() {
		this.validate();
		if (this.isValid && !this.loading) {
			this.loading = true;
			this.linkWithEmail();
		}
	}

	openTerms() {
		TosPage.navigate(this.router);
	}

	linkWithEmail() {
		if (!this.user) {
			console.log('no user to link with email');
			return;
		}
		this.user
			.linkWithCredential(EmailAuthProvider.credential(this.email, this.password))
			.then(async res => {
				if (res.user.emailVerified) {
					await AccountPage.navigate(this.router);
				} else {
					let customerCreated = false;
					try {
						const response = await Api.createCustomer({
							userUid: res.user.uid,
							name: this.fullName,
							email: this.email,
							gender: this.gender,
						});
						customerCreated = true;
						const customer = response.data;
						await res.user.updateProfile({
							displayName: customer.name,
						});
						await Api.sendEmailVerification(res.user.uid);
						this.repository._customer = customer;
						this.repository.customer.emit(customer);
						await EmailConfirmationPage.navigate(this.router);
						this.analytics.emailVerificationPageOpened();
					} catch (e) {
						await this.user.unlink(EmailAuthProvider.PROVIDER_ID);
						if (customerCreated) {
							try {
								await Api.deleteAccount(this.user.uid);
							} finally {
							}
						}
						this.snackbarCtrl.open(e, null, {
							duration: 2000,
						});
					}
				}
				this.loading = false;
			})
			.catch(linkErr => {
				if (linkErr.code === 'auth/email-already-in-use') {
					this.snackbarCtrl.open(this.translate.instant('sign_up.validation.email_duplicate'), null, {
						duration: 2000,
					});
					this.loading = false;
					return;
				}
				console.error(linkErr);
				this.snackbarCtrl.open(linkErr.message, null, {
					duration: 2000,
				});
				this.loading = false;
			});
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
