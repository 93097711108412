import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from '../components/toolbar/toolbar.component';
import { IonicModule } from '@ionic/angular';
import { ArticleOptionGroupFlavorComponent } from '../components/article-option-group-flavor/article-option-group-flavor.component';
import { ItemSelectableArticleComponent } from '../components/item-selectable-article/item-selectable-article.component';
import { ToOrderButtonComponent } from '../components/to-order-button/to-order-button.component';
import { OrderListComponent } from '../components/order-list/order-list.component';
import { ToggleButtonGroupComponent } from '../components/toggle-button-group/toggle-button-group.component';
import { MenuPopoverComponent } from '../components/menu-popover/menu-popover.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MyOrderComponent } from '../components/my-order/my-order.component';
import { QRCodeModule } from 'angular2-qrcode';
import { ItemViewComponent } from '../components/item-view/item-view.component';
import { ArticleOptionGroupSingleComponent } from '../components/article-option-group-single/article-option-group-single.component';
import { VenueSuggestionComponent } from '../components/venue-suggestion/venue-suggestion.component';
import { HomePopoverComponent } from '../components/home-popover/home-popover.component';
import { ArticleOptionGroupCheckboxComponent } from '../components/article-option-group-checkbox/article-option-group-checkbox.component';
import { ArticleOptionGroupOverlayComponent } from '../components/article-option-group-overlay/article-option-group-overlay.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../app.module';
import { BurgerBtnComponent } from '../components/burger-btn/burger-btn.component';
import { ToolbarSmallComponent } from '../components/toolbar-small/toolbar-small.component';
import { ArticleOptionGroupMultipleComponent } from '../components/article-option-group-multiple/article-option-group-multiple.component';
import { RouterModule } from '@angular/router';
import { ToolbarLargeComponent } from '../components/toolbar-large/toolbar-large.component';
import { PromoCodeComponent } from '../components/promo-code/promo-code.component';
import { OrderContentComponent } from '../components/order-content/order-content.component';
import { FormsModule } from '@angular/forms';
import { LangStringPipe } from '../pipes/lang-string.pipe';
import { CheckoutRecommendationsComponent } from '../components/checkout-recommendations/checkout-recommendations.component';
import { CheckoutRecommendationArticleComponent } from '../components/checkout-recommendation-article/checkout-recommendation-article.component';

@NgModule({
	declarations: [
		ToolbarComponent,
		ToolbarLargeComponent,
		ToolbarSmallComponent,
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ItemSelectableArticleComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		MenuPopoverComponent,
		VenueSuggestionComponent,
		MyOrderComponent,
		HomePopoverComponent,
		ItemViewComponent,
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupOverlayComponent,
		ArticleOptionGroupMultipleComponent,
		BurgerBtnComponent,
		PromoCodeComponent,
		LangStringPipe,
		OrderContentComponent,
		CheckoutRecommendationsComponent,
		CheckoutRecommendationArticleComponent,
	],
	exports: [
		LangStringPipe,
		ToolbarComponent,
		ToolbarLargeComponent,
		ToolbarSmallComponent,
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupOverlayComponent,
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ArticleOptionGroupMultipleComponent,
		ItemSelectableArticleComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		HomePopoverComponent,
		VenueSuggestionComponent,
		MenuPopoverComponent,
		BurgerBtnComponent,
		MyOrderComponent,
		ItemViewComponent,
		TranslateModule,
		PromoCodeComponent,
		OrderContentComponent,
		CheckoutRecommendationsComponent,
		CheckoutRecommendationArticleComponent,
	],
	imports: [
		CommonModule,
		IonicModule,
		MatSnackBarModule,
		QRCodeModule,
		TranslateModule.forChild({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		RouterModule,
		FormsModule,
	],
})
export class SharedModuleModule {}
