import { Component, Input } from '@angular/core';
import Article from '../../../models/Article';
import Utils from '../../../utils';
import { PreorderType } from '../../../enums/PreorderType';
import { OrderType } from '../../../enums/OrderType';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['./item-view.component.scss'],
})
export class ItemViewComponent {
	@Input() article: Article;
	@Input() currency: string;
	@Input() preorderType: PreorderType;
	utils = Utils;
	ot = OrderType;

	constructor() {}
	getArticlePrice(article: Article) {
		let totalPrice = Utils.getPrice(article, OrderType.PREORDER, this.preorderType);
		const tagPrice = this.findTagPrice(this.article);
		if (tagPrice && tagPrice > 0) {
			totalPrice = totalPrice - tagPrice;
		}
		return totalPrice;
	}
	findTagPrice(article: Article) {
		if (article && article.tags && article.tags.length > 0) {
			const foundTag = article?.tags?.find(it => it?.identifier === 'deposit_25' || it?.identifier === 'deposit_15');
			if (foundTag) {
				return foundTag.identifier === 'deposit_25' ? 0.25 : foundTag.identifier === 'deposit_15' ? 0.15 : 0;
			}
		} else {
			return null;
		}
	}
}
