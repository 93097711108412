import Order from '../models/Order';
import { OrderType } from '../enums/OrderType';
import ArticleGroup from '../models/ArticleGroup';
import Venue from '../models/Venue';
import { PreorderType } from '../enums/PreorderType';
import ArticleOption from '../models/ArticleOption';
import OptionGroup from '../models/OptionGroup';
import FulfilledDependency from '../models/FulfilledDependency';
import { Moment } from 'moment';
import Utils, { numberD } from '../utils';
import { TimeUtils } from './time-utils';
import { TerminalorderType } from '../enums/TerminalorderType';
import { PromoCodeType } from '../models/PromoCodeType';
import { TranslateService } from '@ngx-translate/core';
import PromoCode from '../models/PromoCode';
import { DisplayIdentifier } from '../app/enums/DisplayIdentifier';
import Article from '../models/Article';
import { ValidationUtils } from './validation-utils';
import { AnalyticsService } from '../app/services/analytics/analytics.service';
import { calculateGeoDistance } from './maps-utils';
import TagUtils from './tag-utils';
import { Fee } from '../models/MovAndFee';

export const sanitizePhoneCountry = (order: Order) => {
	if (isPreorder(order) && !order.preorder.phoneCountry) {
		if (order.preorder.phone) {
			const pc = Utils.phoneToPhoneCountryAndPhone(order.preorder.phone);
			order.preorder.phoneCountry = pc.phoneCountry;
			order.preorder.phone = pc.phone;
		} else {
			order.preorder.phoneCountry = Utils.phoneCountries[0];
		}
	}
};
export const formattedOrderTotalPrice = (order: Order, deliveryFee: boolean, promoCode: boolean, articles = true) => {
	return Utils.numberToCurrency(orderTotalPrice(order, deliveryFee, promoCode, articles), order ? order.currency : null);
};

export const orderTotalPrice = (order: Order, deliveryFee: boolean, promoCode: boolean, articles = true) => {
	const articlePrices = +order.orderedArticles
		.map(orderedArticle => {
			const casted: any = orderedArticle;
			if (casted.totalPrice) {
				return numberD(casted.totalPrice);
			}
			return totalPrice(
				orderedArticle,
				order.type,
				order.preorder ? order.preorder.type : null,
				order.terminalorder ? order.terminalorder.type : null
			);
		})
		.reduce((prev, curr) => prev.valueOf() + curr.valueOf(), 0);
	const fees = deliveryFee ? getDeliveryFees(order) : 0;
	let promoValue = 0;
	if (promoCode && order.promoCode && order.promoCode.type) {
		switch (order.promoCode.type) {
			case PromoCodeType.ABSOLUTE:
				promoValue = +order.promoCode.value;
				break;
			case PromoCodeType.RELATIVE:
				promoValue = articlePrices * +order.promoCode.value;
				break;
			case PromoCodeType.DELIVERY_FEE:
				promoValue = fees;
				break;
			case PromoCodeType.FREE_ARTICLE:
				promoValue = totalPrice(
					order.orderedArticles.find(artGrp => artGrp.isPromo),
					order.type,
					order.preorder.type
				);
				break;
			case PromoCodeType.BOGO:
				promoValue = bogoPrice(
					order.orderedArticles.find(artGrp => artGrp.isPromo),
					order.type,
					order.preorder.type
				);
				break;
		}
	}
	return (articles ? articlePrices : 0) + fees - promoValue;
};

export const bogoPrice = (articleGroup: ArticleGroup, orderType: OrderType, preorderType: PreorderType) => {
	const basePrice = Utils.getPrice(articleGroup.article, orderType, preorderType);
	const fullPriceOptions = articleGroup.groups.filter(artOpt =>
		articleGroup.article.groups.find(
			grp => grp._id === artOpt.group && grp.displayIdentifiers.indexOf(DisplayIdentifier.fullPrice) >= 0
		)
	);
	return (
		basePrice +
		fullPriceOptions
			.map(artOpt => Utils.getPrice(artOpt.article, orderType, preorderType) * artOpt.quantity)
			.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
	);
};

export const isPreorder = (order: Order) => {
	return order && order.type === OrderType.PREORDER && !!order.preorder;
};

export const isDelivery = (order: Order) => {
	return isPreorder(order) && order.preorder.type === PreorderType.DELIVERY;
};

export const isParkCollect = (order: Order) => {
	return isPreorder(order) && order.preorder.type === PreorderType.PARK_COLLECT;
};

export const isFreeDeliveryPromo = (order: Order) => {
	return isDelivery(order) && hasPromo(order) && order.promoCode.type === PromoCodeType.DELIVERY_FEE;
};

export const isBogoOrFreeArticlePromo = (order: Order) => {
	return (
		isPreorder(order) &&
		hasPromo(order) &&
		(order.promoCode.type === PromoCodeType.BOGO || order.promoCode.type === PromoCodeType.FREE_ARTICLE)
	);
};

export const isAbsoluteOrRelativePromo = (order: Order) => {
	return (
		isPreorder(order) &&
		hasPromo(order) &&
		(order.promoCode.type === PromoCodeType.RELATIVE || order.promoCode.type === PromoCodeType.ABSOLUTE)
	);
};

export const hasPromo = (order: Order) => {
	return order && order.promoCode && order.promoCode._id && order.promoCode.type;
};

export const applyPromo = (translate: TranslateService, venue: Venue, order: Order, promoCode: PromoCode, analytics: AnalyticsService) => {
	if (orderTotalPriceWithoutDiscounts(order) < promoCode.mov) {
		throw translate.instant('promo_code.mov_not_reached', {
			mov: Utils.numberToCurrency(promoCode.mov, order.currency),
		});
	}
	if (promoCode.type === PromoCodeType.DELIVERY_FEE && !isDelivery(order)) {
		throw translate.instant('promo_code.free_delivery_wrong_type');
	}
	switch (promoCode.type) {
		case PromoCodeType.BOGO:
			// promoCode.value is array of article masterIds
			const possibleBogos = order.orderedArticles.filter(
				artGrp => promoCode.value.indexOf(artGrp.article.masterId) >= 0 || promoCode.value.indexOf(artGrp.article._id) >= 0
			);
			if (possibleBogos.length === 0) {
				throw translate.instant('promo_code.no_bogo_in_cart');
			} else {
				let candidate = possibleBogos[0];
				let currentBogoPrice = bogoPrice(candidate, order.type, order.preorder.type);
				for (const possibleBogo of possibleBogos) {
					const possibleBogoPrice = bogoPrice(possibleBogo, order.type, order.preorder.type);
					if (possibleBogoPrice < currentBogoPrice) {
						currentBogoPrice = possibleBogoPrice;
						candidate = possibleBogo;
					}
				}
				const bogo: ArticleGroup = JSON.parse(JSON.stringify(candidate));
				bogo.quantity = 1;
				bogo.isPromo = true;
				addToOrder(order, bogo, analytics);
			}
			break;
		case PromoCodeType.FREE_ARTICLE:
			const allArticles: Article[] = [];
			venue.articleCategories.forEach(cat => allArticles.push(...cat.articles));
			// promoCode.value is  article masterId
			const freeArticle = allArticles.find(art => promoCode.value.indexOf(art.masterId) >= 0);
			const freeArticleGroup = new ArticleGroup();
			freeArticleGroup.article = freeArticle;
			freeArticleGroup.quantity = 1;
			freeArticleGroup.isPromo = true;
			freeArticleGroup.freeArticle = true;
			addToOrder(order, freeArticleGroup, analytics);
			break;
	}
	order.promoCode = promoCode;
	return order;
};
export const removePromo = (order: Order) => {
	const removedPromo = order.promoCode;
	order.promoCode = null;
	if (!removedPromo) {
		return order;
	}
	switch (removedPromo.type) {
		case PromoCodeType.DELIVERY_FEE:
		case PromoCodeType.ABSOLUTE:
		case PromoCodeType.RELATIVE:
			break;
		case PromoCodeType.BOGO:
		case PromoCodeType.FREE_ARTICLE:
			order.orderedArticles = order.orderedArticles.filter(artGrp => !artGrp.isPromo);
			break;
	}
	return order;
};

export const orderTotalPriceWithoutDiscounts = (order: Order) => {
	return orderTotalPrice(order, true, false);
};

export const articleGroupsTotalPrice = (
	articleGroups: ArticleGroup[],
	orderType: OrderType,
	preorderType: PreorderType,
	terminalorderType: TerminalorderType = null
) => {
	return articleGroups
		.map(articleGroup => totalPrice(articleGroup, orderType, preorderType, terminalorderType))
		.reduce((prev, curr) => prev + curr, 0);
};

export const totalPrice = (
	articleGroup: ArticleGroup,
	orderType: OrderType,
	preorderType: PreorderType,
	terminalOrderType: TerminalorderType = null
) => {
	return (
		(+Utils.getPrice(articleGroup.article, orderType, preorderType, terminalOrderType) +
			articleGroup.groups
				.map(option => {
					return +Utils.getPrice(option.article, orderType, preorderType, terminalOrderType) * option.quantity;
				})
				.reduce((prev, curr) => prev + curr, 0)) *
		articleGroup.quantity
	);
};

export const injectRequiredArticles = (venue: Venue, order: Order, analytics: AnalyticsService) => {
	if (!venue || !order) {
		return;
	}
	const requiredArticles = Utils.getRequiredArticles(venue, order.preorder.type);
	const requiredArticleGroups: ArticleGroup[] = [];
	for (const requiredArticle of requiredArticles) {
		if (!order.orderedArticles.find(oa => oa.article._id === requiredArticle._id)) {
			const ag = new ArticleGroup();
			ag.article = requiredArticle;
			ag.groups = Utils.defaultsToArticleOption(requiredArticle, [], requiredArticle.defaults, order.preorder.type);
			ag.quantity = 1;
			requiredArticleGroups.push(ag);
		}
	}
	for (const requiredArticleGroup of requiredArticleGroups) {
		addToOrder(order, requiredArticleGroup, analytics);
	}
};

export const injectDeliveryFees = (venue: Venue, order: Order) => {
	if (!venue || !order) {
		if (isPreorder(order)) {
			order.preorder.deliveryFee = undefined;
		}
		return;
	}
	if (!isPreorder(order)) {
		return;
	}
	if (!isDelivery(order)) {
		order.preorder.deliveryFee = undefined;
		return;
	}
	const articleSum = articleGroupsTotalPrice(
		order.orderedArticles,
		order.type,
		order.preorder ? order.preorder.type : null,
		order.terminalorder ? order.terminalorder.type : null
	);
	order.preorder.deliveryFee = findMovAndFees(venue, order, articleSum).fee;
};

// eslint-disable-next-line @typescript-eslint/no-shadow
export const findFees = (venue: Venue, order: Order, totalPrice: number) => {
	return findMovAndFees(venue, order, totalPrice).fee;
};

// eslint-disable-next-line @typescript-eslint/no-shadow
export const findMov = (venue: Venue, order: Order, totalPrice: number) => {
	return findMovAndFees(venue, order, totalPrice).mov;
};

// eslint-disable-next-line @typescript-eslint/no-shadow
export const findMovAndFees = (venue: Venue, order: Order, totalPrice: number) => {
	if (!venue) {
		return undefined;
	}
	if (!isDelivery(order)) {
		return undefined;
	}
	if (!venue.movAndFee) {
		return {
			mov: numberD(venue.movDelivery) ?? 0,
			fee: findDeliveryFeesDeprecated(venue, order, totalPrice),
		};
	}
	if (venue.deliveryByRadius) {
		const distance = calculateGeoDistance(
			order.preorder.lat,
			order.preorder.lng,
			venue.location.coordinates[1],
			venue.location.coordinates[0]
		);
		if (!venue.movAndFee.byRadius || venue.movAndFee.byRadius.length === 0) {
			return {
				mov: numberD(venue.movAndFee.mov),
				fee: numberD(venue.movAndFee.fee),
			};
		}
		const byRadius = venue.movAndFee.byRadius.reduce((prev, curr) => {
			return curr.radius >= distance && curr.radius < prev.radius ? curr : prev;
		});
		if (!byRadius) {
			return {
				mov: numberD(venue.movAndFee.mov),
				fee: numberD(venue.movAndFee.fee),
			};
		}
		return {
			mov: numberD(byRadius ? byRadius.mov : venue.movAndFee.mov),
			fee: numberD(findFee(byRadius.fees, totalPrice)?.fee ?? venue.movAndFee.fee),
		};
	} else {
		const postalCode = order.preorder.postalCode;
		if (!venue.movAndFee.byPostalCodes || venue.movAndFee.byPostalCodes.length === 0) {
			return {
				mov: numberD(venue.movAndFee.mov),
				fee: numberD(venue.movAndFee.fee),
			};
		}
		const byPostalCode = venue.movAndFee.byPostalCodes.find(bpc => bpc.postalCode === postalCode);

		if (!byPostalCode) {
			return {
				mov: numberD(venue.movAndFee.mov),
				fee: numberD(venue.movAndFee.fee),
			};
		}
		return {
			mov: numberD(byPostalCode ? byPostalCode.mov : venue.movAndFee.mov),
			fee: numberD(findFee(byPostalCode.fees, totalPrice)?.fee ?? venue.movAndFee.fee),
		};
	}
};
// eslint-disable-next-line @typescript-eslint/no-shadow
export const findFee = (fees: Fee[], totalPrice: number) => {
	const relevantFees = fees.filter(f => numberD(f.from) <= totalPrice);
	if (relevantFees.length === 0) {
		return null;
	}
	return relevantFees.reduce((prev, curr) => {
		return numberD(prev.from) > numberD(curr.from) ? prev : curr;
	});
};

// eslint-disable-next-line @typescript-eslint/no-shadow
export const findDeliveryFeesDeprecated = (venue: Venue, order: Order, totalPrice: number) => {
	let candidate: any;
	if (order.preorder.postalCode && venue.deliveryFeesPostalCodes && venue.deliveryPostalCodes.length > 0) {
		candidate = venue.deliveryFeesPostalCodes.find(fee => fee.postalCode === order.preorder.postalCode);
		if (candidate) {
			return numberD(candidate.fee);
		}
	}
	if (venue.deliveryFees.length < 1) {
		return undefined;
	}
	candidate = venue.deliveryFees[0];
	for (const deliveryFee of venue.deliveryFees) {
		if (numberD(deliveryFee.from) <= totalPrice && numberD(candidate.fee) > numberD(deliveryFee.fee)) {
			candidate = deliveryFee;
		}
	}
	return numberD(candidate?.fee);
};

export const addSingle = (selectedOptions: ArticleOption[], option: ArticleOption) => {
	const relevantOptions = selectedOptions.filter(value => value.group !== option.group);
	while (selectedOptions.length) {
		selectedOptions.pop();
	}
	selectedOptions.push(...relevantOptions);
	option.quantity = 1;
	selectedOptions.push(option);
};

export const addToOrder = (order: Order, articleGroup: ArticleGroup, analytics: AnalyticsService) => {
	const currentPromoIndex = order.orderedArticles.findIndex(oa => oa.isPromo);
	if (
		currentPromoIndex >= 0 &&
		isBogoOrFreeArticlePromo(order) &&
		order.promoCode.type === PromoCodeType.BOGO &&
		order.promoCode.value.indexOf(articleGroup.article.masterId) >= 0 &&
		bogoPrice(articleGroup, order.type, order.preorder.type) <
			bogoPrice(order.orderedArticles[currentPromoIndex], order.type, order.preorder.type)
	) {
		// new article is added and is bogo and is the smallest bogo price so there is no copy of this article.
		// remove isPromo Flag from previous article
		// add new article twice (once with isPromo true)
		order.orderedArticles[currentPromoIndex].isPromo = false;
		order.orderedArticles.push(articleGroup);
		const bogo = JSON.parse(JSON.stringify(articleGroup));
		bogo.isPromo = true;
		order.orderedArticles.push(bogo);
		analytics.addToCart(order, articleGroup);
		return;
	}
	const index = order.orderedArticles.findIndex(orderedArticle => {
		return (
			orderedArticle.article._id === articleGroup.article._id &&
			articleGroup.groups.length === orderedArticle.groups.length &&
			articleGroup.isPromo === orderedArticle.isPromo &&
			articleGroup.groups
				.map(
					option =>
						orderedArticle.groups.findIndex(
							orderedOption =>
								option.article._id === orderedOption.article._id &&
								option.quantity === orderedOption.quantity &&
								option.dependency === orderedOption.dependency &&
								option.dependsOn === orderedOption.dependsOn &&
								option.dependencyNumber === orderedOption.dependencyNumber
						) >= 0
				)
				.reduce((previousValue, currentValue) => previousValue && currentValue, true)
		);
	});
	if (index >= 0) {
		order.orderedArticles[index].quantity++;
		order.orderedArticles[index].isRecommendedRecipe =
			order.orderedArticles[index].isRecommendedRecipe || articleGroup.isRecommendedRecipe;
	} else {
		order.orderedArticles.push(articleGroup);
		analytics.addToCart(order, articleGroup);
	}
};

export const addOption = (options: ArticleOption[], option: ArticleOption, group: OptionGroup, dependency: FulfilledDependency) => {
	if (!dependency) {
		console.error('No dependency');
		return;
	}
	if (dependency.times < 0) {
		console.log('Not adding option. Dependency not fulfilled');
		return;
	}
	if (dependency.times > 0) {
		option.dependencyNumber = dependency.times;
		option.dependsOn = dependency.dependsOn;
		option.dependency = dependency.dependency._id;
	}
	const selection = filterMatchingOptions(options, group, dependency);
	if (group.limit === 1) {
		addSingle(options, option);
		return;
	}
	const emptyIndex = options.findIndex(emptyOption => emptyOption.group === group._id && TagUtils.hasEmptyTag(emptyOption.article));
	const count =
		selection.map(value => value.quantity).reduce((previousValue, currentValue) => previousValue + currentValue, 0) + option.quantity;
	if (count > group.limit) {
		const indexOfFirst = options.indexOf(selection.find(value => value.article._id !== option.article._id));
		if (indexOfFirst >= 0) {
			if (options[indexOfFirst].quantity > 1) {
				options[indexOfFirst].quantity -= 1;
			} else {
				options.splice(indexOfFirst, 1);
			}
		}
	}
	const index = options.findIndex(value => value.article._id === option.article._id);
	if (index >= 0 && !group.hasMultiple) {
		Utils.removeFromArray(options, index);
	} else if (option.group === group._id && option.article.tags && option.article.tags.find(tag => tag.identifier === 'empty')) {
		if (emptyIndex >= 0) {
			Utils.removeFromArray(options, emptyIndex);
		} else {
			addSingle(options, option);
		}
	} else {
		if (emptyIndex >= 0) {
			Utils.removeFromArray(options, emptyIndex);
		}
		const matchedIndex = options.findIndex(optionToMatch => {
			return (
				optionToMatch.group === option.group &&
				optionToMatch.article._id === option.article._id &&
				optionToMatch.dependency === option.dependency &&
				optionToMatch.dependsOn === option.dependsOn &&
				optionToMatch.dependencyNumber === option.dependencyNumber
			);
		});
		if (matchedIndex >= 0) {
			const requirements = options[matchedIndex].article.requirements;
			if (requirements) {
				if (
					(requirements.min !== -1 && requirements.min > options[matchedIndex].quantity + option.quantity) ||
					(requirements.max !== -1 && requirements.max < options[matchedIndex].quantity + option.quantity)
				) {
					console.log({
						message: 'Could not remove or add option',
						requirements,
						option: option.article.name.de,
						presentQuantity: options[matchedIndex].quantity,
						modifyBy: option.quantity,
					});
					return;
				}
			}
			options[matchedIndex].quantity += option.quantity;
			if (options[matchedIndex].quantity <= 0) {
				Utils.removeFromArray(options, matchedIndex);
			}
		} else {
			options.push(option);
		}
	}
};

export const filterMatchingOptions = (options: ArticleOption[], group: OptionGroup, dependency: FulfilledDependency) => {
	return options.filter(value => {
		return (
			group._id === value.group &&
			((!value.dependency && dependency.times === 0) ||
				(dependency.times === value.dependencyNumber &&
					dependency.dependsOn === value.dependsOn &&
					dependency.dependency._id === value.dependency))
		);
	});
};

export const slotConflictingArticlesInOrder = (slot: Moment, order: Order) => {
	const conflictingArticles: ArticleGroup[] = [];
	for (const articleGroup of order.orderedArticles) {
		if (!TimeUtils.doesHoursMatch(slot, articleGroup.article.availableHours)) {
			conflictingArticles.push(articleGroup);
		}
	}
	return conflictingArticles;
};

export const getDeliveryFees = (order: Order) => {
	if (!isDelivery(order)) {
		return 0;
	}
	if (!order.preorder.deliveryFee) {
		return 0;
	}
	return numberD(order.preorder.deliveryFee);
};

export const validateOrder = (venue: Venue, order: Order) => {
	const orderValue = orderTotalPrice(order, false, true);
	let mov = order.preorder.type === PreorderType.DELIVERY ? findMov(venue, order, orderValue) : 0;
	let articlesValid = order.orderedArticles.length !== 0;
	if (hasPromo(order)) {
		mov = Math.max(mov, +order.promoCode.mov);
	}
	for (const oa of order.orderedArticles) {
		if (!ValidationUtils.areGroupsValid(oa, oa.article.groups)) {
			articlesValid = false;
			break;
		}
	}
	return {
		valid: orderValue > mov && articlesValid,
		movDifference: orderValue - mov,
	};
};
