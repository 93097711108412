import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SignUpPage } from '../sign-up/sign-up.page';
import validator from 'validator';
import { RepositoryService } from '../../services/repository/repository.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderPage } from '../order/order.page';
import { AlertController } from '@ionic/angular';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page.component';
import { Api } from '../../../api/api';
import { environment } from '../../../environments/environment';
import { delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ValidationUtils } from '../../../utils/validation-utils';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.page.html',
	styleUrls: ['./sign-in.page.scss'],
})
export class SignInPage implements OnInit {
	static url = 'sign-in';
	isValid = false;
	loading = false;
	showPassword = false;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private auth: AngularFireAuth,
		private snackbarCtrl: MatSnackBar,
		private alertCtrl: AlertController,
		private route: ActivatedRoute
	) {}

	// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
	private _email = '';

	get email(): string {
		return this._email;
	}

	set email(value: string) {
		this._email = value;
		this.validate();
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
	private _password = '';

	get password(): string {
		return this._password;
	}

	set password(value: string) {
		this._password = value.replace(' ', '');
		this.validate();
	}

	static async navigate(router: Router, redirectToOrder: boolean = false) {
		await router.navigateByUrl(SignInPage.url, {
			state: {
				redirectToOrder,
			},
		});
	}

	static async navigateWithParams(router: Router, queryParams: Params) {
		await router.navigate([SignInPage.url], {
			queryParams,
			replaceUrl: true,
		});
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(async params => {
			if (!params.oobCode || !params.mode) {
				return;
			}
			if (params.mode !== 'resetPassword') {
				return;
			}
			await this.showNewPasswordDialog(params);
		});
	}

	signUp() {
		SignUpPage.navigate(this.router);
	}

	validate() {
		this.isValid = this.password && this.password.length > 5 && validator.isEmail(this.email);
	}

	signIn() {
		this.validate();
		if (!this.isValid) {
			this.snackbarCtrl.open(this.translate.instant('errors.auth_error'), null, {
				duration: 2000,
			});
			return;
		}
		this.loading = true;
		this.auth
			.signInWithEmailAndPassword(this.email, this.password)
			.then(async () => {
				if (history.state.redirectToOrder) {
					await OrderPage.navigate(this.router);
				} else if (this.repository._order === null) {
					await HomePage.navigate(this.router);
				} else {
					await MenuPage.navigate(this.router);
				}
				this.snackbarCtrl.open(this.translate.instant('sign_in.success'), null, {
					duration: 2000,
				});
				this.loading = false;
			})
			.catch(err => {
				this.snackbarCtrl.open(this.translate.instant('sign_in.error'), null, {
					duration: 2000,
				});
				console.error(err);
				this.loading = false;
			});
	}

	showResetPasswordDialog() {
		this.alertCtrl
			.create({
				header: this.translate.instant('reset_password.header'),
				message: this.translate.instant('reset_password.msg'),
				inputs: [
					{
						name: 'email',
						placeholder: this.translate.instant('reset_password.email'),
						type: 'email',
					},
				],
				buttons: [
					{
						text: this.translate.instant('reset_password.cancel_btn'),
						role: 'cancel',
						cssClass: 'secondary',
					},
					{
						text: this.translate.instant('reset_password.ok_btn'),
						handler: async result => {
							if (validator.isEmail(result.email)) {
								try {
									await Api.resetPassword({ email: result.email, customerGroup: environment.customerGroup });
									this.snackbarCtrl.open(this.translate.instant('reset_password.success'), null, {
										duration: 2000,
									});
								} catch (err) {
									this.snackbarCtrl.open(this.translate.instant('reset_password.error'), null, {
										duration: 2000,
									});
									console.log(err);
								}
							} else {
								this.showResetPasswordDialog();
							}
						},
					},
				],
			})
			.then(alert => alert.present());
	}

	async showNewPasswordDialog(params) {
		try {
			const response = await this.auth.verifyPasswordResetCode(params.oobCode);
			const passwordResetAlert = await this.alertCtrl.create({
				header: this.translate.instant('new_password.header'),
				message: this.translate.instant('new_password.msg', { response }),
				inputs: [
					{
						name: 'password1',
						placeholder: this.translate.instant('new_password.password'),
						type: 'password',
					},
					{
						name: 'password2',
						placeholder: this.translate.instant('new_password.re_password'),
						type: 'password',
					},
				],
				buttons: [
					{
						text: this.translate.instant('new_password.cancel_btn'),
						role: 'cancel',
						cssClass: 'secondary',
					},
					{
						text: this.translate.instant('new_password.ok_btn'),
						handler: async result => {
							if (result.password1 !== result.password2) {
								this.snackbarCtrl.open(this.translate.instant('new_password.password_match_error'), null, {
									duration: 2000,
								});
								await delay(2000);
								await this.showNewPasswordDialog(params);
								return;
							}
							if (!ValidationUtils.validatePassword(result.password1)) {
								this.snackbarCtrl.open(this.translate.instant('new_password.password_error'), null, {
									duration: 6000,
								});
								await delay(2000);
								await this.showNewPasswordDialog(params);
								return;
							}
							this.auth
								.confirmPasswordReset(params.oobCode, result.password1)
								.then(async () => {
									await SignInPage.navigate(this.router);
									this.snackbarCtrl.open(this.translate.instant('new_password.success'), null, {
										duration: 2000,
									});
								})
								.catch(err => {
									this.snackbarCtrl.open(this.translate.instant('new_password.error') + err, null, {
										duration: 2000,
									});
								});
						},
					},
				],
			});
			await passwordResetAlert.present();
		} catch (e) {
			if (e.code === 'auth/invalid-action-code') {
				this.snackbarCtrl.open(this.translate.instant('new_password.invalid_action_code'), null, {
					duration: 2000,
				});
			}
			console.error(e);
		}
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
