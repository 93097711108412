import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import ArticleGroup from '../../../models/ArticleGroup';
import { OrderListAction } from '../../enums/OrderListAction';
import Utils from '../../../utils';
import ArticleOption from '../../../models/ArticleOption';
import { DisplayIdentifier } from '../../enums/DisplayIdentifier';
import { OrderType } from '../../../enums/OrderType';
import Article from '../../../models/Article';
import { ValidationUtils } from '../../../utils/validation-utils';
import Order from '../../../models/Order';
import { bogoPrice, isBogoOrFreeArticlePromo, totalPrice } from '../../../utils/order-utils';

@Component({
	selector: 'app-order-list',
	templateUrl: './order-list.component.html',
	styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements DoCheck {
	@Output() action = new EventEmitter<OrderListAction>();
	@Input() editable = true;
	Utils = Utils;
	isSomeOptionFullPrice = false;
	articlePrice: number;
	needsConfiguration = false;
	articleGroupPrice = 0;
	private prevPromoState = false;
	private prevQuantity = 1;

	constructor(private cd: ChangeDetectorRef) {}

	private _articleGroup: ArticleGroup;

	get articleGroup(): ArticleGroup {
		return this._articleGroup;
	}

	@Input()
	set articleGroup(value: ArticleGroup) {
		this._articleGroup = value;
		this.reloadArticle();
	}

	private _order: Order;

	get order(): Order {
		return this._order;
	}

	@Input()
	set order(value: Order) {
		this._order = value;
		this.reloadArticle();
	}

	edit() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.edit);
	}

	isEditable(): boolean {
		return this.editable;
	}

	delete() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.delete);
	}

	async note() {
		if (!this.editable) {
			return;
		}
		this.action.emit(OrderListAction.note);
	}

	add() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.add);
	}

	remove() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.remove);
	}

	priceOfOption(articleOption: ArticleOption): number {
		let price = Utils.getPrice(articleOption.article, OrderType.PREORDER, this.order.preorder.type);
		if (this.isOptionFullPrice(articleOption)) {
			price = price + this.articlePrice;
			if (isBogoOrFreeArticlePromo(this.order) && this.articleGroup.isPromo) {
				return 0;
			}
		}
		return price * articleOption.quantity;
	}

	isOptionFullPrice(articleOption: ArticleOption): boolean {
		const group = this.articleGroup.article.groups.find(grp => grp._id === articleOption.group);
		return group && group.displayIdentifiers && group.displayIdentifiers.findIndex(di => DisplayIdentifier.fullPrice === di) >= 0;
	}

	hiddenInCart(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}

	reloadArticle() {
		this.needsConfiguration = false;
		this.isSomeOptionFullPrice = false;
		this.articleGroupPrice = 0;
		this.prevPromoState = false;
		this.prevQuantity = 1;
		if (!this.articleGroup || !this.order || !this.order.preorder) {
			this.articlePrice = null;
			return;
		}
		this.prevQuantity = this.articleGroup.quantity;
		this.prevPromoState = this.articleGroup.isPromo;
		this.needsConfiguration = !ValidationUtils.areGroupsValid(this.articleGroup, this.articleGroup.article.groups);
		if (isBogoOrFreeArticlePromo(this.order) && this.articleGroup.isPromo) {
			this.articlePrice = 0;
		} else {
			this.articlePrice = Utils.getPrice(this.articleGroup.article, this.order.type, this.order.preorder.type);
		}
		for (const articleOption of this.articleGroup.groups) {
			if (this.isOptionFullPrice(articleOption)) {
				this.isSomeOptionFullPrice = true;
			}
		}
		this.articleGroupPrice = totalPrice(this.articleGroup, this.order.type, this.order.preorder.type);
		if (isBogoOrFreeArticlePromo(this.order) && this.articleGroup.isPromo) {
			this.articleGroupPrice -= bogoPrice(this.articleGroup, this.order.type, this.order.preorder.type);
		}
	}

	ngDoCheck(): void {
		if (this.prevPromoState !== this.articleGroup.isPromo) {
			this.reloadArticle();
			this.cd.markForCheck();
		}
		if (this.prevQuantity !== this.articleGroup.quantity) {
			this.reloadArticle();
			this.cd.markForCheck();
		}
	}
	checkArticlePfand(article: Article) {
		if (article && article.tags && article.tags.length > 0) {
			return article.tags.find(it => it.identifier === 'deposit_25' || it.identifier === 'deposit_15');
		} else {
			return false;
		}
	}
	findTagPrice(article: Article) {
		if (article && article.tags && article.tags.length > 0) {
			const foundTag = article.tags.find(it => it.identifier === 'deposit_25' || it.identifier === 'deposit_15');
			if (foundTag) {
				return foundTag.identifier === 'deposit_25' ? 0.25 : foundTag.identifier === 'deposit_15' ? 0.15 : 0;
			}
		} else {
			return null;
		}
	}
	calculatePriceWithoutPfand(articleGroup: ArticleGroup) {
		if (articleGroup && articleGroup.article.tags && articleGroup.article.tags.length > 0) {
			const foundTag = articleGroup?.article?.tags?.find(it => it?.identifier === 'deposit_25' || it?.identifier === 'deposit_15');
			if (foundTag) {
				const price = foundTag.identifier === 'deposit_25' ? 0.25 : 0.15;
				return this.articleGroupPrice - price;
			}
			return 0;
		} else {
			return 0;
		}
	}
}
