import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../models/OptionGroup';
import ArticleOption from '../../../models/ArticleOption';
import Article from '../../../models/Article';
import Utils from '../../../utils';
import { OrderType } from '../../../enums/OrderType';
import { PreorderType } from '../../../enums/PreorderType';

@Component({
	selector: 'app-article-option-group-multiple',
	templateUrl: './article-option-group-multiple.component.html',
	styleUrls: ['./article-option-group-multiple.component.scss'],
})
export class ArticleOptionGroupMultipleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() currency: string;
	@Input() preorderType: PreorderType;

	utils = Utils;
	ot = OrderType;

	constructor() {}

	increaseOption(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.quantity = 1;
		option.group = this.optionGroup._id;
		this.selection.emit(option);
	}

	decreaseOption(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.quantity = -1;
		option.group = this.optionGroup._id;
		this.selection.emit(option);
	}

	count(article: Article): number {
		return this.selected
			.map(articleOption => (articleOption.article._id === article._id ? articleOption.quantity : 0))
			.reduce((prev, curr) => prev + curr, 0);
	}

	totalCount(): number {
		return this.selected
			.map(articleOption => (articleOption.group === this.optionGroup._id ? articleOption.quantity : 0))
			.reduce((prev, curr) => prev + curr, 0);
	}

	hasEmptyTag(optionArticle: Article): boolean {
		return optionArticle.tags && optionArticle.tags.length > 0 && optionArticle.tags.findIndex(tag => tag.identifier === 'empty') >= 0;
	}

	isHidden(option: Article): boolean {
		return option.hidden || !Utils.getAvailability(option, OrderType.PREORDER, this.preorderType);
	}
}
