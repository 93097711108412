import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Venue from '../../../models/Venue';
import { MapsUtils } from '../../../utils/maps-utils';
import { Api } from '../../../api/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IonInput, IonSlides, ModalController } from '@ionic/angular';
import { RepositoryService } from '../../services/repository/repository.service';
import { TranslateService } from '@ngx-translate/core';
import Utils, { coverFlow } from '../../../utils';
import { PreorderType } from '../../../enums/PreorderType';
import { MenuPage } from '../menu/menu.page.component';
import { Router } from '@angular/router';
import RepositoryDirective from '../repository-directive';
import Address from '../../../models/Address';
import { DeliveryNotAvailableModalComponent } from '../../components/delivery-not-available-modal/delivery-not-available-modal.component';
import { DeliveryNotAvailableAction } from '../../enums/DeliveryNotAvailableAction';
import { ParkCollectSheetComponent } from '../../components/park-collect-sheet/park-collect-sheet.component';
import { TimeUtils } from '../../../utils/time-utils';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;

@Component({
	selector: 'app-map',
	templateUrl: './map.page.html',
	styleUrls: ['./map.page.scss'],
})
export class MapPage extends RepositoryDirective implements OnInit {
	static url = 'map';

	@ViewChild('mapContainer', { static: true })
	mapElement: ElementRef;
	@ViewChild(IonInput, { static: true })
	inputField: IonInput;
	@ViewChild(IonSlides, { static: true })
	slides: IonSlides;
	map: Map;
	mapOptions: MapOptions = {
		maxZoom: 15,
		minZoom: 5,
		disableDefaultUI: true,
		clickableIcons: false,
		styles: [
			{
				featureType: 'poi.business',
				stylers: [{ visibility: 'off' }],
			},
		],
	};
	clusterer: MarkerClusterer;
	loading = false;
	loadingAddress = false;
	predictions: Address[] = [];
	showHint = false;
	searchTerm: string;
	allVenues: Venue[] = [];
	localVenues: Venue[] = [];
	selectedVenueForDelivery: Venue;
	showMap = true;
	slidesOpts = {
		slidesPerView: 1,
		coverflowEffect: {
			rotate: 0,
			stretch: 15,
			depth: 10,
			modifier: 2,
		},
		on: coverFlow,
	};
	selectedVenue: Venue;
	MapsUtils = MapsUtils;
	private _showPredictions = false;

	constructor(
		private snackbarCtrl: MatSnackBar,
		public repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	get relevantVenues(): Venue[] {
		return this.address ? this.localVenues : this.allVenues;
	}

	static navigate(router: Router) {
		return router.navigateByUrl(MapPage.url);
	}

	ngOnInit() {
		super.ngOnInit();

		this.map = new Map(this.mapElement.nativeElement, this.mapOptions);
		new Promise<void>(async resolve => {
			await this.fillInPlace(null);
			await this.initAutocomplete();
			resolve();
		});
	}

	async resetMap() {
		if (this.allVenues.length === 0) {
			try {
				this.allVenues = (await Api.getAllVenues()).data
					.map(ven => {
						try {
							ven.openingHours = TimeUtils.sanitizeHours(ven.openingHours, ven.specialOpeningHours ?? []);
							ven.deliveryHours = TimeUtils.sanitizeHours(ven.deliveryHours);
						} catch (e) {
							console.error({
								message: 'Error while sanitizing hours ' + e,
								venue: ven.name + ' ' + ven.readableId,
								openingHours: ven.openingHours,
								deliveryHours: ven.deliveryHours,
							});
						}
						return ven;
					})
					.filter(
						ven => Utils.venueAcceptsOrders(ven, PreorderType.DELIVERY) || Utils.venueAcceptsOrders(ven, PreorderType.TAKE_AWAY)
					);
			} catch (e) {}
		}
		await this.setupMap(null);
	}

	async setupMap(selectedVenue: Venue) {
		if (this.loading) {
			return;
		}
		this.selectedVenue = selectedVenue;
		this.loading = true;
		this.cdr.detectChanges();
		this.clusterer = MapsUtils.addVenuesToMap(this.clusterer, selectedVenue, this.relevantVenues, this.map, venue =>
			this.setupMap(venue)
		);
		if (selectedVenue && selectedVenue.location && selectedVenue.location.coordinates) {
			const selectedIndex = this.relevantVenues.findIndex(ven => ven._id === selectedVenue._id);
			// wait until slides rendered
			await this.slides.length();
			await this.slides.slideTo(selectedIndex);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	hidePredictions() {
		setTimeout(() => {
			this._showPredictions = false;
		}, 200);
	}

	showPredictions() {
		this._showPredictions = true;
	}

	async executeSearch() {
		console.log('executeSearch()');
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			await this.fillInPlace(result);
		} catch (e) {}
		this.loading = false;
	}

	resetSearch() {
		console.log('resetSearch()');
		this.searchTerm = '';
		this.predictions = [];
		this.selectedVenueForDelivery = null;
		this.selectedVenue = null;
		if (this.address) {
			this.repository.address.emit(null);
		}
		this.showHint = false;
		this.cdr.detectChanges();
	}

	async fillInPlace(address: Address) {
		console.log('fillInPlace()');
		this.loading = true;
		try {
			this.searchTerm = MapsUtils.checkAddress(address);
			this.loading = false;
			if (this.address !== address) {
				this.repository.address.emit(address);
			}
			await this.onSlideChange();
		} catch (e) {
			console.error(e);
			this.snackbarCtrl.open(this.translate.instant(e), null, {
				duration: 2000,
			});
		}
		this.loading = false;
	}

	async loadVenues(address: Address) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.cdr.detectChanges();
		this.localVenues = [];
		try {
			this.localVenues = await this.repository.getVenuesByAddress(address);
			this.searchTerm = MapsUtils.addressToString(address);
			if (this.localVenues.length === 0 && !this.selectedVenueForDelivery) {
				this.snackbarCtrl.open(
					this.translate.instant('map_page.no_venues_in_address', {
						title: this.searchTerm,
					}),
					null,
					{
						duration: 2000,
					}
				);
				this.loading = false;
				await this.resetSearch();
				this.cdr.detectChanges();
				return;
			}
			if (this.selectedVenueForDelivery) {
				const deliveryVenues = this.localVenues.filter(ven => Utils.venueAcceptsOrders(ven, PreorderType.DELIVERY));
				const selectedDeliveryVenue = deliveryVenues.find(ven => ven._id === this.selectedVenueForDelivery._id);
				if (selectedDeliveryVenue) {
					await this.selectVenue(selectedDeliveryVenue, PreorderType.DELIVERY);
				} else {
					const choice = await DeliveryNotAvailableModalComponent.show(this.modalCtrl);
					switch (choice) {
						case DeliveryNotAvailableAction.DECLINE:
							this.localVenues = deliveryVenues;
							if (deliveryVenues.length === 0) {
								this.repository.address.emit(null);
							} else {
								this.showHint = true;
							}
							await this.setupMap(null);
							break;
						case DeliveryNotAvailableAction.TAKE_AWAY:
							await this.selectVenue(this.selectedVenueForDelivery, PreorderType.TAKE_AWAY);
							break;
					}
				}
				this.selectedVenueForDelivery = null;
				return;
			}
			this.selectedVenue = null;
			this.loading = false;
			await this.setupMap(this.selectedVenue);
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async onSlideChange() {
		let index = await this.slides.getActiveIndex();
		if (index >= this.relevantVenues.length) {
			index = 0;
		}
		await this.setupMap(this.relevantVenues[index]);
	}

	async selectVenue(venue: Venue, preorderType: PreorderType, attempt: number = 0, prevError: any = null) {
		if (preorderType === PreorderType.DELIVERY && !this.address) {
			this.snackbarCtrl.open(this.translate.instant('map_page.enter_delivery_address'), null, {
				duration: 2000,
			});
			this.selectedVenueForDelivery = venue;
			return;
		}
		if (
			preorderType === PreorderType.DELIVERY &&
			venue.distance > venue.deliveryRadius &&
			venue.deliveryByRadius &&
			!venue.isPostalDelivery
		) {
			this.snackbarCtrl.open(this.translate.instant('map_page.venue_does_not_delivery_to_address'), null, {
				duration: 2000,
			});
			return;
		}
		if (attempt > 5) {
			this.loading = false;
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000,
			});
			return;
		}
		this.loading = true;
		if (preorderType === PreorderType.PARK_COLLECT) {
			preorderType = await ParkCollectSheetComponent.show(this.modalCtrl, venue);
		}
		try {
			await this.repository.getVenue(venue._id);
			this.repository.createOrder(venue, this.address, preorderType);
			await MenuPage.navigate(this.router);
			this.loading = false;
		} catch (e) {
			await this.selectVenue(venue, preorderType, attempt + 1, e);
		}
	}

	async initAutocomplete() {
		await MapsUtils.initAutocomplete(
			this.inputField,
			predictions => {
				this.predictions = predictions;
				this.cdr.detectChanges();
			},
			loading => (this.loadingAddress = loading)
		);
		this.loading = false;
	}

	async loadPlace(pred: Address) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		await this.fillInPlace(pred);
		this.loading = false;
		this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
		new Promise(async () => {
			if (this.address) {
				await this.loadVenues(this.address);
			} else {
				await this.resetMap();
				this.resetSearch();
			}
		});
	}

	setShowMap(value: boolean) {
		this.showMap = value;
		this.cdr.detectChanges();
	}
}
