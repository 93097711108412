import * as moment from 'moment';
import { Moment } from 'moment';
import Hours from '../models/Hours';
import SpecialHours from 'src/models/SpecialHours';
import { scheduled } from 'rxjs';

export class TimeUtils {
	static doesHoursMatch(mom: Moment, schedule: Hours[]): boolean {
		if (!schedule || schedule.length === 0) {
			return true;
		}
		let weekday = mom.weekday() - 1;
		if (weekday < 0) {
			weekday = 6;
		}
		for (const hour of schedule) {
			if (hour.weekday !== weekday) {
				continue;
			}
			const hours = TimeUtils.hoursToSchedule(hour);
			if (mom.isBetween(hours.openedAt, hours.closedAt)) {
				return true;
			}
		}
		return false;
	}
	static doesHoursMatchWithCelebration(mom: Moment, schedule: Hours[], specialHours: SpecialHours[] = []): boolean {
		if (!schedule || schedule.length === 0) {
			return true;
		}
		let weekday = mom.weekday() - 1;
		if (weekday < 0) {
			weekday = 6;
		}
		if (specialHours.length > 0) {
			const foundSpecialDay = specialHours.find(specialDay => moment(specialDay?.date).isSame(moment(), 'day'));
			if (foundSpecialDay) {
				if (foundSpecialDay?.isClosed) {
					return false;
				}
				const specialHours = this.transformSpecialHours(foundSpecialDay);

				console.log('specialHours', specialHours, mom.isBetween(specialHours.openedAt, specialHours.closedAt));
				return mom.isBetween(specialHours.openedAt, specialHours.closedAt);
			}
		}
		for (const hour of schedule) {
			if (hour.weekday !== weekday) {
				continue;
			}
			const hours = TimeUtils.hoursToSchedule(hour);

			console.log(hours);
			if (mom.isBetween(hours.openedAt, hours.closedAt)) {
				return true;
			}
		}
		return false;
	}
	static transformSpecialHours(specialHours: SpecialHours) {
		const now = moment().second(0).millisecond(0);
		const openedAtTokens = specialHours.openedAt.split(':').map(token => Number(token));
		const closedAtTokens = specialHours.closedAt.split(':').map(token => Number(token));
		let openedAt = this.tokensToMoment(now, openedAtTokens, false);
		let closedAt = this.tokensToMoment(now, closedAtTokens, false);
		if (closedAt.isBefore(openedAt)) {
			closedAt = closedAt.add(1, 'days');
		}
		const nowWeekday = now.weekday();
		const daysToAdd =
			moment(specialHours.date).weekday() < nowWeekday
				? 7 - (nowWeekday - moment(specialHours.date).weekday())
				: moment(specialHours.date).weekday() - nowWeekday;
		openedAt = openedAt.add(daysToAdd, 'days');
		closedAt = closedAt.add(daysToAdd, 'days');

		return {
			openedAt: openedAt,
			closedAt: closedAt,
		};
	}
	static doesHoursMatchNow(schedule: Hours[]): boolean {
		return TimeUtils.doesHoursMatch(moment().seconds(0).milliseconds(0), schedule);
	}

	/**
	 * Create array of dates on this week
	 * @param hours Hours to sanitize
	 */
	static createArrayOfWeek(): Moment[] {
		var startOfWeek = moment().startOf('week').add(1, 'day');
		var endOfWeek = moment().endOf('week').add(1, 'day');

		var days = [];
		var day = startOfWeek;

		while (day <= endOfWeek) {
			days.push(day.toDate());
			day = day.clone().add(1, 'd');
		}

		return days;
	}
	/**
	 * Converts hours to LocalTime and puts Hours together if they are one after the other
	 * @param hours Hours to sanitize
	 */
	static sanitizeHours(hours: Hours[], specialHours: SpecialHours[] = []): Hours[] {
		if (!hours || hours.length === 0) {
			return [];
		}
		const result: Hours[] = [];
		const weekArrayDates = this.createArrayOfWeek();
		for (const day of weekArrayDates) {
			const hoursArrayFound = hours.filter(hour => {
				let weekday = moment(day).weekday() - 1;
				if (weekday < 0) {
					weekday = 6;
				}

				return weekday === hour.weekday;
			});
			hoursArrayFound.forEach(hour => {
				if (hour) {
					try {
						const dateExistIndex = weekArrayDates.findIndex(it => {
							let weekday = moment(it).weekday() - 1;
							if (weekday < 0) {
								weekday = 6;
							}
							return weekday === hour.weekday;
						});
						const specialDates = specialHours.filter(it =>
							moment(it.date).isSame(moment(weekArrayDates[dateExistIndex]), 'day')
						);

						if (specialHours.length > 0 && dateExistIndex >= 0 && specialDates.length > 0) {
							specialDates.forEach(specialDate => {
								const specialHoursObject = this.transformSpecialHours(specialDate);
								let weekday = moment(specialDate.date).weekday() - 1;
								if (weekday < 0) {
									weekday = 6;
								}
								if (!specialDate.isClosed) {
									const localHour = new Hours();

									localHour.weekday = weekday;
									localHour.openedAt = specialHoursObject.openedAt.format('HH:mm');
									localHour.closedAt = specialHoursObject.closedAt.format('HH:mm');
									result.push(localHour);
								} else {
									if (specialDate.isClosed && specialDate.closedAt && specialDate.openedAt) {
										const schedule = TimeUtils.hoursToSchedule(hour, false);

										if (moment(specialHoursObject.openedAt).isAfter(schedule.openedAt)) {
											const localHour = new Hours();
											localHour.openedAt = schedule.openedAt.format('HH:mm');
											localHour.closedAt = specialHoursObject.openedAt.format('HH:mm');
											localHour.weekday = weekday;

											result.push(localHour);
										}
										if (moment(schedule.closedAt).isAfter(specialHoursObject.closedAt)) {
											const localHour = new Hours();
											localHour.openedAt = specialHoursObject.closedAt.format('HH:mm');
											localHour.closedAt = schedule.closedAt.format('HH:mm');
											localHour.weekday = weekday;

											result.push(localHour);
										}
										if (moment(specialHoursObject.closedAt).isAfter(schedule.closedAt)) {
											const localHour = new Hours();
											localHour.openedAt = schedule.closedAt.format('HH:mm');
											localHour.closedAt = specialHoursObject.closedAt.format('HH:mm');
											localHour.weekday = weekday;

											result.push(localHour);
										}
										// localHour.weekday = hour.weekday;
										// localHour.openedAt = schedule.openedAt.format('HH:mm');
										// localHour.closedAt = schedule.closedAt.format('HH:mm');
									}
								}
							});
						} else {
							const schedule = TimeUtils.hoursToSchedule(hour, false);
							const localHour = new Hours();

							localHour.weekday = hour.weekday;
							localHour.openedAt = schedule.openedAt.format('HH:mm');
							localHour.closedAt = schedule.closedAt.format('HH:mm');
							result.push(localHour);
							if (localHour.openedAt === '00:00') {
								const prevDay = localHour.weekday === 0 ? 6 : localHour.weekday - 1;
								const prev = result.find(prevHour => prevHour.weekday === prevDay && prevHour.closedAt === '23:59');
								if (prev) {
									prev.closedAt = localHour.closedAt;
									prev.connectedWithNext = true;
									localHour.connectedWithPrev = true;
								}
							}
						}
					} catch (e) {
						console.error(e);
					}
				}
			});
			if (hoursArrayFound.length === 0) {
				const specialDates = specialHours.filter(it => moment(it.date).isSame(moment(day), 'day'));
				if (specialDates && specialDates.length > 0) {
					specialDates.forEach(specialDate => {
						if (!specialDate?.isClosed) {
							const specialHoursObject = this.transformSpecialHours(specialDate);
							const localHour = new Hours();

							let weekday = moment(specialDate.date).weekday() - 1;
							if (weekday < 0) {
								weekday = 6;
							}
							localHour.weekday = weekday;
							localHour.openedAt = specialHoursObject.openedAt.format('HH:mm');
							localHour.closedAt = specialHoursObject.closedAt.format('HH:mm');
							result.push(localHour);
						}
					});
				}
			}
		}
		console.log('RESULT', result);

		const first = result[0];
		if (first.openedAt === '00:00') {
			const prevDay = first.weekday === 0 ? 6 : first.weekday - 1;
			const prev = result.find(prevHour => prevHour.weekday === prevDay && prevHour.closedAt === '23:59');
			if (prev) {
				prev.closedAt = first.closedAt;
				prev.connectedWithNext = true;
				first.connectedWithPrev = true;
			}
		}
		return TimeUtils.sortHours(result);
	}
	static sortHours(hours: Hours[]): Hours[] {
		return hours.sort((a, b) => {
			if (a.weekday < b.weekday) {
				return -1;
			}
			if (a.weekday > b.weekday) {
				return 1;
			}
			if (a.weekday === b.weekday) {
				const openAtA = +a.openedAt.replace(':', '');
				const openAtB = +b.openedAt.replace(':', '');
				if (openAtA < openAtB) {
					return -1;
				}
				if (openAtA > openAtB) {
					return 1;
				}
				return 0;
			}
		});
	}

	static hoursToSchedule(
		hour: Hours,
		localTime: boolean = true,
		specialHours: SpecialHours[] = []
	): {
		openedAt: Moment;
		closedAt: Moment;
	} | null {
		if (!hour) {
			return null;
		}
		const now = moment().second(0).millisecond(0);
		const openedAtTokens = hour.openedAt.split(':').map(token => Number(token));
		const closedAtTokens = hour.closedAt.split(':').map(token => Number(token));
		let openedAt = this.tokensToMoment(now, openedAtTokens, localTime);
		let closedAt = this.tokensToMoment(now, closedAtTokens, localTime);
		if (hour.connectedWithNext || closedAt.isBefore(openedAt)) {
			closedAt = closedAt.add(1, 'days');
		}
		const nowWeekday = now.weekday() === 0 ? 6 : now.weekday() - 1;
		const daysToAdd = hour.weekday < nowWeekday ? 7 - (nowWeekday - hour.weekday) : hour.weekday - nowWeekday;
		openedAt = openedAt.add(daysToAdd, 'days');
		closedAt = closedAt.add(daysToAdd, 'days');
		// if (specialHours.length > 0) {
		// 	const foundSpecialDay = specialHours.find(it => moment(it.date).isSame(openedAt, 'day'))
		// 	if (foundSpecialDay) {
		// 		const transformSpecialDayToHours = this.transformSpecialHours(foundSpecialDay);
		// 		if (foundSpecialDay?.isClosed) {
		// 			return null
		// 		}
		// 		console.log('transformSpecialHours', this.transformSpecialHours(foundSpecialDay))
		// 		return {
		// 			openedAt: transformSpecialDayToHours.openedAt,
		// 			closedAt: transformSpecialDayToHours.closedAt
		// 		}
		// 	}

		// }
		return {
			openedAt,
			closedAt,
		};
	}

	static tokensToMoment(now: Moment, tokens: number[], localTime: boolean): Moment {
		let mom = moment();
		if (!localTime) {
			mom = mom.utc();
		}
		mom = mom.hour(tokens[0]).minute(tokens[1]).second(0).millisecond(0);
		if (!localTime) {
			mom = mom.local().date(now.date()).month(now.month()).year(now.year());
			if (!mom.isDST()) {
				mom = mom.add(1, 'hour');
			}
		}
		return mom;
	}

	static getSchedulesOfDay(mom: Moment, schedule: Hours[]): Hours[] {
		return schedule.filter(hour => {
			let weekday = mom.weekday() - 1;
			if (weekday < 0) {
				weekday = 6;
			}
			return weekday === hour.weekday;
		});
	}

	static isSameDate(m1: Moment, m2: Moment): boolean {
		return m1.dayOfYear() === m2.dayOfYear() && m1.year() === m2.year();
	}
}
