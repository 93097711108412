import { PreorderType } from '../enums/PreorderType';
import { PhoneCountry } from '../utils';
import Address from './Address';

export default class Preorder extends Address {
	// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
	_id: string;
	type: PreorderType;
	personCount: number;
	name: string;
	email: string;
	phone: string;
	phoneCountry: PhoneCountry;
	note: string;
	companyName: string;
	subcard: string;
	floor: string;
	deliveryFee: number | any;
	licenseNumber: string;
	vehicleBrand: string;
	vehicleColor: string;
}
