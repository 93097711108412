import {
	formattedOrderTotalPrice,
	isBogoOrFreeArticlePromo,
	isDelivery,
	isParkCollect,
	isPreorder,
	orderTotalPrice,
	slotConflictingArticlesInOrder,
	totalPrice,
} from '../../../utils/order-utils';
import { OrderType } from '../../../enums/OrderType';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonSelect, IonSlides, ModalController, PickerController } from '@ionic/angular';
import { RepositoryService } from '../../services/repository/repository.service';
import Utils from '../../../utils';
import * as moment from 'moment';
import { Moment } from 'moment';
import { SubwayCardModalComponent } from '../subway-card-modal/subway-card-modal.component';
import { PreorderType } from '../../../enums/PreorderType';
import validator from 'validator';
import { TosPage } from '../../pages/tos/tos.page';
import { PrivacyPage } from '../../pages/privacy/privacy.page';
import RepositoryDirective from '../../pages/repository-directive';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ValidationUtils } from '../../../utils/validation-utils';
import { Api } from '../../../api/api';
import { calculateGeoDistance, MapsUtils } from '../../../utils/maps-utils';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import Preorder from '../../../models/Preorder';
import TagUtils from '../../../utils/tag-utils';
import Order from '../../../models/Order';
import ArticleOption from '../../../models/ArticleOption';
import ArticleGroup from '../../../models/ArticleGroup';
import Article from '../../../models/Article';
import { AppComponent } from 'src/app/app.component';

@Component({
	selector: 'app-checkout-modal',
	templateUrl: './checkout-modal.component.html',
	styleUrls: ['./checkout-modal.component.scss'],
})
export class CheckoutModalComponent extends RepositoryDirective implements OnInit {
	utils = Utils;
	loading = false;
	now: moment.Moment;
	pt = PreorderType;
	nameError: string;
	emailError: string;
	phoneError: string;
	streetNumberError: string;
	loyaltyNumberError: string;
	orderAtError: string;
	streetError: string;
	postalCodeError: string;
	vehicleColorError: string;
	vehicleBrandError: string;
	licenseNumberError: string;
	hadPostalCode = false;
	isOpeningTimeSheet = false;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover',
	};
	loyaltyNumberVerified: boolean = null;
	loyaltyNumberMask = {
		mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
	};
	isVenueOpen = false;
	formattedOrderTotalPrice = formattedOrderTotalPrice;
	orderTotalPrice = orderTotalPrice;
	totalPrice = totalPrice;
	@ViewChild(IonContent, { static: false }) content;
	@ViewChild(IonSelect, { static: false }) select;
	@ViewChild(IonSlides, { static: false }) slides: IonSlides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false,
	};
	isOverviewPage = false;

	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private pickerCtrl: PickerController,
		private translate: TranslateService,
		private router: Router,
		private analytics: AnalyticsService
	) {
		super(repository);
		if (this.address && this.address.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
		if (this.order && this.order.preorder && this.order.preorder.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
	}

	static async show(
		modalCtrl: ModalController,
		analytics: AnalyticsService,
		order: Order,
		tip: number = 0
	): Promise<{ asap: boolean; orderAt: Moment; preorder: Preorder; tip?: number }> {
		const modal = await modalCtrl.create({
			component: CheckoutModalComponent,
			componentProps: {
				tip,
			},
			showBackdrop: true,
			cssClass: AppComponent.largeScreen ? 'large-modal' : '',
			backdropDismiss: true,
		});
		await modal.present();
		analytics.beginCheckout(order);
		const result = (await modal.onDidDismiss()).data;
		await Utils.sleep(100);
		return result;
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.beginCheckout(this.order);
		this.analytics.checkoutProgress(this.order, null, 1);
		this.analytics.customerBeginsCheckout();
	}

	onOrder() {
		super.onOrder();
		const postalCodeInOrder =
			this.order && this.order.preorder && this.order.preorder.postalCode && this.order.preorder.postalCode.length > 0;
		if (this.order && this.order.preorder) {
			if (this.repository._previousPreorder) {
				if (!this.order.preorder.name) {
					this.order.preorder.name = this.repository._previousPreorder.name;
				}
				if (!this.order.preorder.email) {
					this.order.preorder.email = this.repository._previousPreorder.email;
				}
				if (!this.order.preorder.phone) {
					this.order.preorder.phone = this.repository._previousPreorder.phone;
				}
				if (!this.order.preorder.phoneCountry) {
					this.order.preorder.phoneCountry = this.repository._previousPreorder.phoneCountry;
				}
				if (!this.order.preorder.companyName) {
					this.order.preorder.companyName = this.repository._previousPreorder.companyName;
				}
				if (!this.order.preorder.floor) {
					this.order.preorder.floor = this.repository._previousPreorder.floor;
				}
				if (!this.order.preorder.subcard) {
					this.order.preorder.subcard = this.repository._previousPreorder.subcard;
				}
				if (
					this.order.preorder.type === PreorderType.PARK_COLLECT &&
					this.repository._previousPreorder.type === PreorderType.PARK_COLLECT
				) {
					this.order.preorder.vehicleColor = this.repository._previousPreorder.vehicleColor;
					this.order.preorder.licenseNumber = this.repository._previousPreorder.licenseNumber;
					this.order.preorder.vehicleBrand = this.repository._previousPreorder.vehicleBrand;
				}
			}
		}
		this.hadPostalCode = this.hadPostalCode || postalCodeInOrder;
	}

	onAddress() {
		super.onAddress();
		this.hadPostalCode = this.hadPostalCode || (this.address && this.address.postalCode && this.address.postalCode.length > 0);
		this.reloadAddress();
	}

	onVenue() {
		this.isVenueOpen = Utils.isVenueOpen(this.venue);
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.isVenueOpen = false;
		if (this.order) {
			this.order.asap = false;
		}
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			if (this.customer.postalCode && this.customer.postalCode.length > 0) {
				this.hadPostalCode = true;
			}
			if (!this.order.preorder.email) {
				this.order.preorder.email = this.customer.email;
			}
			if (!this.order.preorder.name) {
				this.order.preorder.name = this.customer.name;
			}
			if (!this.order.preorder.phone) {
				this.order.preorder.phone = this.customer.phone;
			}
			if (!this.order.preorder.phoneCountry) {
				this.order.preorder.phoneCountry = this.customer.phoneCountry;
			}
			if (!this.order.preorder.companyName) {
				this.order.preorder.companyName = this.customer.companyName;
			}
			if (!this.order.preorder.floor) {
				this.order.preorder.floor = this.customer.floor;
			}
			if (!this.order.preorder.subcard) {
				this.order.preorder.subcard = this.customer.subcard;
			}
			if (this.order.preorder.type === PreorderType.DELIVERY) {
				if (!this.order.preorder.street) {
					this.order.preorder.street = this.customer.street;
				}
				if (!this.order.preorder.postalCode) {
					this.order.preorder.postalCode = this.customer.postalCode;
				}
				if (!this.order.preorder.city) {
					this.order.preorder.city = this.customer.city;
				}
				if (!this.order.preorder.number && this.order.preorder.street === this.customer.street) {
					this.order.preorder.number = this.customer.number;
				}
			}
		}
	}

	async validate(): Promise<boolean> {
		this.nameError = null;
		this.emailError = null;
		this.phoneError = null;
		this.loyaltyNumberError = null;
		this.orderAtError = null;
		this.streetNumberError = null;
		this.postalCodeError = null;
		this.streetError = null;
		this.licenseNumberError = null;
		this.vehicleBrandError = null;
		this.vehicleColorError = null;
		if (!this.venue || !this.order || !this.order.preorder) {
			this.snackbarCtrl.open(this.translate.instant('errors.data_error'), null, {
				duration: 2000,
			});
			return false;
		}
		if (!this.order.preorder.name || this.order.preorder.name.length < 3) {
			this.nameError = this.translate.instant('errors.name');
			this.snackbarCtrl.open(this.nameError, null, {
				duration: 2000,
			});
			return false;
		}
		if (!this.order.preorder.email || !validator.isEmail(this.order.preorder.email)) {
			this.emailError = this.translate.instant('errors.email');
			this.snackbarCtrl.open(this.emailError, null, {
				duration: 2000,
			});
			return false;
		}
		if (!ValidationUtils.validatePhone(this.order.preorder.phoneCountry, this.order.preorder.phone)) {
			this.phoneError = this.translate.instant('errors.phone');
			this.snackbarCtrl.open(this.phoneError, null, {
				duration: 2000,
			});
			return false;
		}

		if (isParkCollect(this.order)) {
			if (Utils.isEmpty(this.order.preorder.licenseNumber)) {
				this.licenseNumberError = this.translate.instant('errors.license_number');
				this.snackbarCtrl.open(this.licenseNumberError, null, {
					duration: 2000,
				});
				return false;
			}
			if (Utils.isEmpty(this.order.preorder.vehicleBrand)) {
				this.vehicleBrandError = this.translate.instant('errors.vehicle_brand');
				this.snackbarCtrl.open(this.vehicleBrandError, null, {
					duration: 2000,
				});
				return false;
			}
			if (Utils.isEmpty(this.order.preorder.vehicleColor)) {
				this.vehicleColorError = this.translate.instant('errors.vehicle_color');
				this.snackbarCtrl.open(this.vehicleColorError, null, {
					duration: 2000,
				});
				return false;
			}
			if (!this.order.orderAt) {
				this.order.asap = true;
			}
		}

		// Address was changed in checkout modal check if venue delivers to this new address
		// new address needs to be checked if its in the delivery range
		if (
			isDelivery(this.order) &&
			(this.order.preorder.street !== this.address.street || this.order.preorder.number !== this.address.number)
		) {
			this.order.preorder.street = this.order.preorder.street.trim();
			this.order.preorder.number = this.order.preorder.number.trim();
			if (Utils.isEmpty(this.order.preorder.street)) {
				this.streetError = this.translate.instant('address.street');
				this.snackbarCtrl.open(this.streetError, null, {
					duration: 2000,
				});
				return false;
			}
			try {
				const pred =
					this.order.preorder.street +
					' ' +
					this.order.preorder.number +
					', ' +
					this.order.preorder.city +
					' ' +
					this.order.preorder.postalCode;
				const address = await MapsUtils.getPlace(pred);
				address.number = address.number ?? this.order.preorder.number;
				const addressValidationResult = ValidationUtils.validateAddress(address);
				if (addressValidationResult) {
					this.streetError = this.translate.instant(addressValidationResult);
					this.snackbarCtrl.open(this.translate.instant('address.check_address'), null, {
						duration: 2000,
					});
					return false;
				}
				const delivers =
					(this.venue.deliveryByRadius &&
						calculateGeoDistance(
							address.lat,
							address.lng,
							this.venue.location.coordinates[1],
							this.venue.location.coordinates[0]
						) <= this.venue.deliveryRadius) ||
					(this.venue.isPostalDelivery && this.venue.deliveryPostalCodes.indexOf(address.postalCode));
				if (!delivers) {
					this.streetError = this.translate.instant('errors.street_delivery');
					this.snackbarCtrl.open(this.streetError, null, {
						duration: 2000,
					});
					return false;
				}
				this.repository.address.emit(address);
				this.order.preorder.street = this.address.street;
			} catch (e) {
				this.streetError = this.translate.instant('errors.street_not_found');
				this.snackbarCtrl.open(this.streetError, null, {
					duration: 2000,
				});
				return false;
			}
		}

		if (this.order.preorder.type === PreorderType.DELIVERY && (!this.order.preorder.number || this.order.preorder.number.length < 1)) {
			this.streetNumberError = this.translate.instant('errors.street_number');
			this.snackbarCtrl.open(this.streetNumberError, null, {
				duration: 2000,
			});
			return false;
		}
		if (
			this.order.preorder.type === PreorderType.DELIVERY &&
			(!this.order.preorder.postalCode || this.order.preorder.postalCode.length < 1)
		) {
			this.postalCodeError = this.translate.instant('errors.postal');
			this.snackbarCtrl.open(this.postalCodeError, null, {
				duration: 2000,
			});
			return false;
		}
		if (
			this.order.preorder.subcard &&
			validator.isInt(this.order.preorder.subcard) &&
			this.order.preorder.subcard.length !== 0 &&
			this.order.preorder.subcard.length !== 16
		) {
			this.loyaltyNumberError = this.translate.instant('errors.subcard');
			this.snackbarCtrl.open(this.loyaltyNumberError, null, {
				duration: 2000,
			});
			return false;
		}
		if (!this.order.orderAt || moment(this.order.orderAt).isBefore(moment())) {
			if ((!isParkCollect(this.order) && !this.order.asap) || !this.isVenueOpen) {
				this.orderAtError = this.translate.instant('errors.order_at');
				this.order.orderAt = null;
				this.snackbarCtrl.open(this.orderAtError, null, {
					duration: 2000,
				});
				return false;
			}
		}
		return true;
	}

	async openTimeSheet() {
		if (isParkCollect(this.order) && this.isVenueOpen) {
			this.order.orderAt = null;
			this.order.asap = true;
			return;
		}
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000,
			});
			return;
		}
		if (this.isOpeningTimeSheet) {
			return;
		}
		this.isOpeningTimeSheet = true;
		this.loading = true;
		try {
			let slots = await Utils.getSlots(
				this.venue,
				this.order.preorder.type,
				this.order.orderedArticles.map(oa => TagUtils.hasExtraSlotsTag(oa.article)).find(bol => bol) !== undefined
			);

			if ((slots.length === 1 && !slots[0]) || slots.length < 1) {
				this.isOpeningTimeSheet = false;
				this.loading = false;
				this.snackbarCtrl.open(this.translate.instant('checkout_modal.no_slots'), null, {
					duration: 2000,
				});
				return;
			}

			if (this.order.orderedArticles.map(oa => TagUtils.hasExtraSlotsTag(oa.article)).find(bol => bol) !== undefined) {
				const dateFormat = 'YYYY-MM-DD HH:mm:ss';

				if (slots[0] && slots[0] && slots[0]?.time && moment(slots[0]?.time).isSame(moment(), 'day')) {
					slots = slots.filter(slot => {
						return moment(moment(slot.time).format(dateFormat)).diff(moment(moment().format(dateFormat)), 'hour') >= 2;
					});
				} else {
					slots = slots.filter(slot => {
						return (
							moment(moment(slot.time).format(dateFormat)).diff(moment(moment(slots[0].time).format(dateFormat)), 'hour') >= 2
						);
					});
				}
			}
			let slotsMap = slots.map(slot => {
				return {
					text: this.slotToText(slot ? moment(slot.time) : null),
					value: slot,
				};
			});
			const options = {
				columns: [
					{
						name: 'slot',
						class: 'time-picker',
						options: slotsMap,
					},
				],
				buttons: [
					{
						text: this.translate.instant('checkout_modal.cancel'),
						role: 'cancel',
					},
					{
						text: this.translate.instant('checkout_modal.confirm'),
						handler: value => {
							const selectedSlot = value.slot.value;
							const conflictingArticles = slotConflictingArticlesInOrder(
								!selectedSlot ? moment() : moment(selectedSlot.time),
								this.order
							);
							if (conflictingArticles.length > 0) {
								this.snackbarCtrl.open(
									this.translate.instant('checkout_modal.products_unavailable') +
										conflictingArticles
											.map(conflictingArticle => conflictingArticle.article.name.de)
											.reduce((previousValue, currentValue) => previousValue + ' ' + currentValue),
									null,
									{
										duration: 3000,
									}
								);
								return;
							}
							if (selectedSlot) {
								this.order.orderAt = moment(selectedSlot.time).local();
							} else {
								this.order.orderAt = null;
							}
						},
					},
				],
			};
			const picker = await this.pickerCtrl.create(options);
			await picker.present();
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('checkout_modal.time_error'), null, {
				duration: 2000,
			});
			console.error(e);
		}
		this.isOpeningTimeSheet = false;
		this.loading = false;
	}

	async openModal() {
		await SubwayCardModalComponent.openModal(this.modalCtrl);
	}

	async close() {
		const data = {
			preorder: this.order.preorder,
			orderAt: this.order.orderAt,
			asap: this.order.asap,
		};
		if (!(await this.validate())) {
			await Utils.sleep(100);
			const firstErrorElement: HTMLElement = [...this.content.el.children].find(el => {
				return el.className.split(' ').findIndex(clazz => clazz === 'input-error') >= 0;
			});
			if (firstErrorElement !== undefined) {
				await this.content.scrollToPoint(0, firstErrorElement.offsetTop, 300);
			}
			return;
		}
		this.repository.previousPreorder.emit(this.order.preorder);
		await this.modalCtrl.dismiss(data);
	}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}

	reloadAddress() {
		if (isDelivery(this.order) && this.address) {
			this.order.preorder.city = this.address.city;
			this.order.preorder.street = this.address.street;
			if (this.address.postalCode && this.address.postalCode.length > 0) {
				this.order.preorder.postalCode = this.address.postalCode;
			}
			this.order.preorder.country = this.address.country;
			this.order.preorder.number = this.address.number;
		}
	}

	async openTos() {
		window.open(TosPage.url, '_blank');
	}

	async openPrivacy() {
		window.open(PrivacyPage.url, '_blank');
	}

	slotToText(slot: Moment): string {
		if (!slot) {
			return this.translate.instant(
				isParkCollect(this.order) && this.isVenueOpen ? 'checkout_modal.first_slot' : 'checkout_modal.choose_slot'
			);
		}
		if (isParkCollect(this.order)) {
			return this.translate.instant('checkout_modal.first_slot') + ' (' + slot.format('HH:mm') + ')';
		}
		const now = moment();
		const sameDay = slot.dayOfYear() === now.dayOfYear() && slot.year() === now.year();
		if (sameDay) {
			return slot.format('HH:mm');
		} else {
			return slot.format('DD.MM. HH:mm');
		}
	}

	async onEmailChange() {
		if (
			(!this.order.preorder.subcard || this.order.preorder.subcard === '') &&
			this.order.preorder.email &&
			validator.isEmail(this.order.preorder.email)
		) {
			try {
				this.order.preorder.subcard = await Api.getSubCardNumberOf(this.order.preorder.email);
				this.loyaltyNumberVerified = true;
			} catch (e) {}
		}
	}

	async onCheckLoyaltyNumber() {
		this.loyaltyNumberError = null;
		if (isPreorder(this.order) && this.order.preorder.subcard) {
			this.order.preorder.subcard = this.order.preorder.subcard.replace(/\s/g, '');
		}
		if (this.order.preorder.subcard && this.order.preorder.subcard !== '' && this.order.preorder.subcard.length === 16) {
			try {
				this.loyaltyNumberVerified = await Api.isSubCardNumberValid(this.order.preorder.subcard);
			} catch (e) {
				this.loyaltyNumberVerified = false;
				this.loyaltyNumberError = this.translate.instant('errors.subcard');
			}
		} else {
			this.loyaltyNumberVerified = null;
		}
	}

	hiddenInCart(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}

	async onButtonClick() {
		if (!(await this.validate())) {
			return;
		}
		if (!this.isOverviewPage) {
			await this.toOverview();
		} else {
			await this.close();
		}
	}

	async toOverview() {
		const result = await this.validate();
		if (!result) {
			this.isOverviewPage = false;
			return;
		}
		await this.slides.update();
		await this.slides.slideNext();
		this.isOverviewPage = true;
	}

	async editPersonalData() {
		await this.slides.slideTo(0);
		this.isOverviewPage = false;
	}

	async backOrDismiss() {
		if (this.isOverviewPage) {
			await this.editPersonalData();
		} else {
			await this.dismiss();
		}
	}

	priceOfOption(articleGroup: ArticleGroup, articleOption: ArticleOption): number {
		const price = this.utils.getPrice(articleOption.article, OrderType.PREORDER, this.order.preorder.type);
		if (isBogoOrFreeArticlePromo(this.order) && articleGroup.isPromo) {
			return 0;
		}
		return price * articleOption.quantity;
	}
}
