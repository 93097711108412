import { Pipe, PipeTransform } from '@angular/core';
import Localization from '../../models/Localization';

@Pipe({ name: 'langString' })
export class LangStringPipe implements PipeTransform {
	transform(value: Localization, lang?: string): string {
		if (!lang) {
			return value.de;
		}
		const translation = value[lang.toLowerCase()];
		if (!translation) {
			return value.de;
		}
		return translation;
	}
}
