import { Gender } from '../enums/Gender';
import Address from './Address';

export default class Customer extends Address {
	_id?: string;
	name?: string;
	companyName?: string;
	preferredVenue?: string;
	gender?: Gender;
	phoneCountry?: { code: string; tel: string };
	phone?: string;
	email: string;
	userUid: string;
	floor?: string;
	subcard?: string;
	loyaltyNumber?: string;
}
