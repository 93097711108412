const { name, version } = require('../../package.json');
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const subwayCustomerGroup = 'subway';
const customerGroup = subwayCustomerGroup;
export const environment = {
	production: false,
	packageName: name,
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	flavor: 'devDebug',
	placesApiKey: 'AIzaSyA8AFm0bugQOXa84diUBbZ_7OB83-cC5ps',
	baseUrl: 'https://dev-api.smoothr.de',
	version,
	SUPPORT_EMAIL: 'subway@smoothr.de',
	firebaseConfig: {
		apiKey: 'AIzaSyASaAcNkBzuBhKXIr-9BmrPPmoyeianKeU',
		authDomain: 'subway-dev-auth.firebaseapp.com',
		databaseURL: 'https://subway-dev-auth.firebaseio.com',
		projectId: 'subway-dev-auth',
		storageBucket: 'subway-dev-auth.appspot.com',
		messagingSenderId: '727918559871',
		appId: '1:727918559871:web:6d2ede46225dd31efdb54a',
		measurementId: 'G-K63EM9PFBP',
	},
	VR_URL: 'https://test.vr-pay-ecommerce.de/v1/paymentWidgets.js',
	PAYPAL: {
		BN_CODE: 'SterlixGmbH_MP_Connected',
		JS_URL: 'https://www.paypal.com/sdk/js?client-id=Ac5vAqN4prOBX3YUFPX_L9Bmz3ncsdmOgkz7LuE2zP3yDbIaJB0y3SD4CTBdJqoHQhcWP6-WMklA-thJ&integration-date=2020-09-04&components=buttons,funding-eligibility&currency=',
	},
	APAY_MERCHANT_ID: 'merchant.smoothr.subway-dev',
	GPAY: {
		GATEWAY: 'aciworldwide',
		MERCHANT_ID: 'BCR2DN6T5OYNT6CG',
	},
	countryList: ['de', 'au'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
