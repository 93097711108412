import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page.component';
import { RepositoryService } from '../../services/repository/repository.service';
import { Legal } from 'src/models/Legal';
import { Api } from 'src/api/api';
import { NavController } from '@ionic/angular';

@Component({
	selector: 'app-impressum',
	templateUrl: './impressum.page.html',
	styleUrls: ['./impressum.page.scss'],
})
export class ImpressumPage implements OnInit {
	static url = 'impressum';
	legal: Legal;

	constructor(
		private repository: RepositoryService,
		private router: Router,
		private route: ActivatedRoute,
		private navCtrl: NavController
	) {}

	static async navigate(router: Router) {
		await router.navigateByUrl(ImpressumPage.url);
	}

	static async navigateWithParams(router: Router, queryParams: Params) {
		await router.navigate([ImpressumPage.url], {
			queryParams,
		});
	}

	ngOnInit() {
		this.route.queryParams.subscribe(async params => {
			if (!params.venueId) {
				return;
			}
			if (params.venueId) {
				this.venueLegal(params.venueId);
			}
		});
	}

	async venueLegal(venueId: string) {
		try {
			this.legal = (await Api.getLegal(venueId)).data;
			if (this.legal.type === 'pdf') {
				window.open(this.legal.imprint, '_blank');
				this.navCtrl.back();
			}
		} catch (e) {
			console.error('Error while getting legal information');
			console.error(e);
		}
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
