import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AlertController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from '../../../services/repository/repository.service';
import { HomePage } from '../../home/home.page';
import { Api } from '../../../../api/api';
import Utils from '../../../../utils';
import Venue from '../../../../models/Venue';
import { PreorderType } from '../../../../enums/PreorderType';
import RepositoryDirective from '../../repository-directive';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { TimeUtils } from 'src/utils/time-utils';
import { AppComponent } from '../../../app.component';
import { environment } from '../../../../environments/environment';
import { formattedOrderTotalPrice, isParkCollect, isPreorder, orderTotalPrice } from '../../../../utils/order-utils';
import Payment from '../../../../models/Payment';

@Component({
	selector: 'app-success',
	templateUrl: './success.page.html',
	styleUrls: ['./success.page.scss'],
})
export class SuccessPage extends RepositoryDirective implements OnInit {
	static url = 'payment/success';
	static urlWithPaymentParam = 'payment/success/:payment';
	static ORDER_ID_PARAM = 'order';
	moment = moment;
	TimeUtils = TimeUtils;
	utils = Utils;
	pt = PreorderType;
	orderVenue: Venue;
	error: any;
	loyaltyPoints: number;
	orderId: string;
	isParkCollect = isParkCollect;
	formattedOrderTotalPrice = formattedOrderTotalPrice;

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private alertCtrl: AlertController,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	static async navigate(router: Router, order: string, payment: string) {
		await router.navigate([this.url + '/' + payment], {
			queryParams: {
				order,
			},
		});
	}

	ngOnInit() {
		super.ngOnInit();
		console.log(this.route.snapshot.params);
		if (!this.payment) {
			this.payment = new Payment();
			this.payment._id = this.route.snapshot.params.payment;
		}
	}

	track() {
		if (!AppComponent.analyticsEnabled || !environment.production || !this.payment?._id) {
			return;
		}
		const rtid = this.payment._id;
		const ramount = orderTotalPrice(this.verifiedOrder, true, true);
		const rcustomer = this.verifiedOrder.userUid;
		const rcpid = '9';
		const r2ps = document.createElement('script');
		const rproduct = 'Sale';
		r2ps.type = 'text/javascript';
		r2ps.src =
			'https://www.essenceperformancenetwork.com/get_trans.cgi?clickid=' +
			escape('') +
			'&js=1&cpid=' +
			escape(rcpid) +
			'&tid=' +
			escape(rtid) +
			'&umsatz=' +
			escape(ramount.toFixed(2)) +
			'&kunde=' +
			escape(rcustomer) +
			'&produkt=' +
			escape(rproduct);

		(document.getElementsByTagName('head')[0] || document.body).appendChild(r2ps);
	}

	ionViewDidEnter() {
		this.repository.order.emit(null);
	}

	async onInitFinish() {
		super.onInitFinish();
		this.route.queryParams.subscribe(params => {
			console.log({
				params,
			});

			if (this.verifiedOrder && this.verifiedOrder._id && !params.order) {
				this.orderId = this.verifiedOrder._id;
			} else {
				this.orderId = params.order;
			}
			this.reloadOrder();
		});
	}

	async reloadOrder(attempt: number = 0, error = null) {
		this.error = null;
		if (attempt > 10) {
			this.error = error ? error : this.translate.instant('payment_success_page.payment_error');
			this.snackbarCtrl.open(error, null, {
				duration: 2000,
			});
			return;
		}
		if (attempt > 0) {
			await Utils.sleep(200);
		}
		Api.getOrder(this.orderId)
			.then(async orderResponse => {
				this.repository.verifiedOrder.emit(orderResponse.data);
				if (!this.verifiedOrder.isPayed) {
					await this.reloadOrder(attempt + 1, this.translate.instant('payment_success_page.please_reload'));
					return;
				}
				this.analytics.paymentSuccess();
				this.track();
				try {
					if (this.venue && this.venue._id === this.verifiedOrder.venue) {
						this.orderVenue = this.venue;
					} else {
						this.orderVenue = (await Api.getLazyVenue(this.verifiedOrder.venue)).data;
					}
					if (isPreorder(this.verifiedOrder) && this.verifiedOrder.preorder.subcard) {
						try {
							this.loyaltyPoints = (await Api.getSubCard(this.verifiedOrder.preorder.subcard)).data.balance;
						} catch (e) {
							console.error(e);
						}
					}
				} catch (e) {
					console.error(e);
					await this.reloadOrder(attempt + 1, error ? error : e);
				}
			})
			.catch(async err => {
				console.error(err);
				await this.reloadOrder(attempt + 1, err);
			});
	}

	home() {
		if (this.error) {
			this.reloadOrder();
			return;
		}
		HomePage.navigate(this.router, this.repository);
	}
}
