import { Component } from '@angular/core';
import Venue from '../../../models/Venue';
import { PreorderType } from '../../../enums/PreorderType';
import Utils from '../../../utils';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-park-collect-sheet',
	templateUrl: './park-collect-sheet.component.html',
	styleUrls: ['./park-collect-sheet.component.scss'],
})
export class ParkCollectSheetComponent {
	venue: Venue;
	utils = Utils;
	PreorderType = PreorderType;
	selection: PreorderType = PreorderType.PARK_COLLECT;
	choice = [PreorderType.PARK_COLLECT, PreorderType.TAKE_AWAY];

	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, venue: Venue): Promise<PreorderType> {
		const modal = await modalCtrl.create({
			component: ParkCollectSheetComponent,
			componentProps: {
				venue,
			},
			cssClass: 'park-collect-sheet auto-height align-bottom-only-mobile',
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await Utils.sleep(200);
		return result;
	}

	async close() {
		await this.modalCtrl.dismiss(null);
	}

	async dismiss() {
		await this.modalCtrl.dismiss(this.selection);
	}
}
