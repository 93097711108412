import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Article from '../../../models/Article';
import { Api } from '../../../api/api';
import CheckoutRecommendation from '../../../models/CheckoutRecommendation';
import Utils from 'src/utils';
import RepositoryDirective from '../../pages/repository-directive';
import { RepositoryService } from '../../services/repository/repository.service';
import { TerminalorderType } from '../../../enums/TerminalorderType';
import { PreorderType } from '../../../enums/PreorderType';

@Component({
	selector: 'app-checkout-recommendations',
	templateUrl: './checkout-recommendations.component.html',
	styleUrls: ['./checkout-recommendations.component.scss'],
})
export class CheckoutRecommendationsComponent extends RepositoryDirective implements OnInit {
	@Output() add = new EventEmitter<Article>();

	Utils = Utils;

	articles: Article[] = [];
	loading = true;
	allArticles: Article[] = [];

	constructor(protected repository: RepositoryService) {
		super(repository);
	}

	private _recommendations: CheckoutRecommendation[] = [];

	get recommendations(): CheckoutRecommendation[] {
		return this._recommendations;
	}

	@Input() set recommendations(value: CheckoutRecommendation[]) {
		this.articles = value.map(rec => this.allArticles.find(all => all._id === rec.articles[0])).filter(art => art);
		this._recommendations = value;
	}

	ngOnInit() {
		super.ngOnInit();
	}

	async loadRecommendations() {
		this.loading = true;
		this.recommendations = [];
		if (this.order.orderedArticles.length < 1) {
			this.loading = false;
			return;
		}
		try {
			this.recommendations = (
				await Api.getCartRecommendations({
					venue: this.order.venue,
					articles: this.order.orderedArticles.map(oa => {
						return {
							_id: oa.article._id,
							quantity: oa.quantity,
						};
					}),
					orderType: this.order.type,
					subOrderType: this.toSubOrderType(this.order.preorder.type ?? this.order.terminalorder.type),
				})
			).data.filter(res => res.articles.length > 0);
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
	}

	toSubOrderType(subType: PreorderType | TerminalorderType): 'delivery' | 'takeAway' | 'inside' {
		switch (subType) {
			case PreorderType.DELIVERY:
				return 'delivery';
			case PreorderType.INSIDE:
			case TerminalorderType.INSIDE:
				return 'inside';
			case TerminalorderType.TAKE_AWAY:
			case PreorderType.TAKE_AWAY:
			case PreorderType.PARK_COLLECT:
				return 'takeAway';
		}
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.allArticles = [];
			return;
		}
		this.venue.articleCategories.forEach(cat => this.allArticles.push(...cat.articles));
	}

	onOrder() {
		super.onOrder();
		if (this.order) {
			this.loadRecommendations();
		}
	}
}
